import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, TitleBox } from '../../shared/Text'

interface ChartProps {
	model: Model
}

const ElderlyBedShortagesForecastChart: React.FC<ChartProps> = ({ model }) => {
	return (
		<>
			<TitleBox>Elderly Bed Supply and Demand Forecast</TitleBox>
			<Paragraph>
				Demand and supply for elderly care beds | Based on Attendance Allowance Data from the Department for Work and
				Pensions, CQC care home data and demographic data from the Census 2021 | Regression-based forecast model from SH
				Proptech
			</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart
				model={model}
				aspectRatio={1}
				dataLabelDisplayOption="showForSelectedLabels"
				selectedLabels={['2020', '2024', 'E2028', 'E2032']}
			/>
		</>
	)
}

export { ElderlyBedShortagesForecastChart }
