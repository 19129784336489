import graphql from 'babel-plugin-relay/macro'

export const OperatorsByLadCode = graphql`
	query OperatorsByLadCodeQuery($ladCode: String!) {
		operatorsByLadCode(ladCode: $ladCode) {
			operators {
				...OperatorsTableFragment
			}
		}
	}
`
