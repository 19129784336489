/**
 * @generated SignedSource<<067dcbe9d831aadf3a1fe9c7adb43bfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesSiteFragment$data = ReadonlyArray<{
  readonly id: any;
  readonly ladCode: string | null | undefined;
  readonly mainPhoneNumber: string | null | undefined;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly onspdLatitude: number | null | undefined;
  readonly onspdLongitude: number | null | undefined;
  readonly " $fragmentType": "SitesSiteFragment";
}>;
export type SitesSiteFragment$key = ReadonlyArray<{
  readonly " $data"?: SitesSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SitesSiteFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SitesSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ladCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onspdLatitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onspdLongitude",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "1cfacbd01851a195e78a6287af2b146c";

export default node;
