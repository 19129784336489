import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareSupplyRateQuickFactByCaFragment = graphql`
	fragment ElderlyCareSupplyRateQuickFactByCaFragment on ElderlyCareStats {
		stats {
			code
			type
			careSupplyRate
			numberOfBeds
			higherRateAllowance
		}
	}
`
