import graphql from 'babel-plugin-relay/macro'

export const OperatorsTableFragment = graphql`
	fragment OperatorsTableFragment on Operator @relay(plural: true) {
		id
		name
		operatorGroupName
		registrationDate
		serviceTypes
		website
		sites {
			id
			careHome
			currentRatings
			registrationDate
			ladCode
			numberOfBeds
			servicesProviders
			specialisms
			regionCode
		}
	}
`
