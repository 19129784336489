import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../../../Constants'
import { GeographiesByCodesQuery } from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByLadCodeQuery } from '../../../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { SocialGradesByLadCodeQuery } from '../../../../graphql/queries/__generated__/SocialGradesByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../../../graphql/queries/GeographiesByCodes'
import { PopulationByLadCode } from '../../../../graphql/queries/PopulationByLad'
import { SocialGradesByLadCode } from '../../../../graphql/queries/SocialGradesByLad'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { TextPlaceholder } from '../../../../shared/placeholder/Placeholder'

interface AIPopulationSummaryProps {
	ladCode: string
}

const AIPopulationSummary: React.FC<AIPopulationSummaryProps> = ({ ladCode }) => {
	const { populationByLadCode: populationAgeGroups } = useLazyLoadQuery<PopulationByLadCodeQuery>(PopulationByLadCode, {
		ladCode,
		startYear: currentYear,
		rangeSize: 20,
		minAge: 0,
		maxAge: 100,
		futureOffset: 5,
		includeIntermediates: false,
	})

	const socialGradeData = useLazyLoadQuery<SocialGradesByLadCodeQuery>(SocialGradesByLadCode, {
		ladCode: ladCode,
	})

	const laName = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes: [ladCode],
	})

	const dataToAnalyse = {
		Population: populationAgeGroups,
		SocialGradeDistribution: socialGradeData,
		LaName: laName,
	}

	const prompt = `
    Analyse the population structure of the Local Authority ${JSON.stringify(dataToAnalyse.LaName)}, comparing to its Region, and England based on the following datasets: 
	Population Growth by Age groups Data: ${JSON.stringify(dataToAnalyse.Population)}
	Social Grade Distribution Data: ${JSON.stringify(dataToAnalyse.SocialGradeDistribution)}
	and structure your answer starting with a headline like an action title of maximum 30 characters wrapping up the main finding followed by a descriptive paragraph between 90-120 characters.
  `
	const iteration = 4
	const key = `population-summary-${ladCode}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIPopulationSummary: React.FC<AIPopulationSummaryProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<AIPopulationSummary ladCode={ladCode} />
		</React.Suspense>
	)
}

export { SuspendedAIPopulationSummary as AIPopulationSummary }
