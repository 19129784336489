/**
 * @generated SignedSource<<287a5c3e54e648b0933dc1b40d8f5a8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LaTableElderlyCareStatsFragment$data = {
  readonly stats: ReadonlyArray<{
    readonly code: string;
    readonly numberOfBeds: number;
    readonly populationOver65: number;
  }>;
  readonly " $fragmentType": "LaTableElderlyCareStatsFragment";
};
export type LaTableElderlyCareStatsFragment$key = {
  readonly " $data"?: LaTableElderlyCareStatsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LaTableElderlyCareStatsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LaTableElderlyCareStatsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElderlyCareData",
      "kind": "LinkedField",
      "name": "stats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "populationOver65",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElderlyCareStats",
  "abstractKey": null
};

(node as any).hash = "a4c31ba6fc0a1b540c4bb0e05e4b49f3";

export default node;
