import { elderlySpecialismKey } from '../Constants'

function filterEldery<T extends { specialisms?: { name: string }[] }>(sites: ReadonlyArray<T>): ReadonlyArray<T> {
	return sites.filter((site) => {
		const { specialisms } = site
		return specialisms && specialisms.some((s) => s.name === elderlySpecialismKey)
	})
}

export { filterEldery }
