import graphql from 'babel-plugin-relay/macro'

export const AAAwardTypesByLadCode = graphql`
	query AAAwardTypesByLadCodeQuery($ladCode: String!) {
		aaAwardTypesByLadCode(ladCode: $ladCode) {
			labels
			higherRate {
				code
				values
			}
			lowerRate {
				code
				values
			}
			total {
				code
				values
			}
		}
	}
`
