import React, { useState } from 'react'
import * as i from 'react-feather'

import { SpaceBetween } from './layout/Alignment'

interface AlertProps {
	children: React.ReactNode
	type?: 'info' | 'warning' | 'danger' | 'success'
	closable?: boolean
}

const Alert: React.FC<AlertProps> = ({ children, type = 'info', closable = false }) => {
	const [visible, setVisible] = useState(true)

	if (!visible) return null

	const classNames = `alert alert-${type}`

	return (
		<div className={classNames} role="alert">
			<SpaceBetween>
				<div>{children}</div>
				{closable && (
					<button
						type="button"
						className="btn"
						style={{ color: 'rgba(0,0,0,0.4)' }}
						aria-label="Close"
						onClick={() => setVisible(false)}
					>
						<i.XCircle size={16} />
					</button>
				)}
			</SpaceBetween>
		</div>
	)
}

export { Alert }
