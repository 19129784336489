// OperatorsTable.tsx

import { CellContext, ColumnDef, flexRender } from '@tanstack/react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { calculateOperatorMetrics, OperatorData } from '../../utils/calculateOperatorMetrics'
import { DataTable, RowData } from '../DataTable'
import { Paragraph } from '../Text'
import { OperatorsTableFragment$data } from './__generated__/OperatorsTableFragment.graphql'

interface OperatorsTableProps {
	name: string
	code: string
	operators: OperatorsTableFragment$data
}

const OperatorsTable: React.FC<OperatorsTableProps> = ({ name, code, operators }) => {
	const navigate = useNavigate()

	const columns = React.useMemo<ColumnDef<RowData, any>[]>(
		() => [
			{ header: 'Name', accessorKey: 'name' },
			{ header: 'Group', accessorKey: 'operatorGroupName' },
			{ header: 'Specialisms', accessorKey: 'specialismsUniqueAggregatedList' },
			{ header: `Elderly Care Homes in ${name}`, accessorKey: 'careHomesLA' },
			{ header: `Beds in ${name}`, accessorKey: 'bedsCountLA' },
			{ header: 'Total Beds', accessorKey: 'currentBedsCountTotal' },
			{ header: `Care Facilities in ${name}`, accessorKey: 'facilitiesCountLA' },
			{ header: 'Care Facilities in England', accessorKey: 'currentSitesCount' },
			{ header: 'Local Authorities active', accessorKey: 'lasActiveListCount' },
			{ header: 'Regions active', accessorKey: 'regionsActiveList' },
			{ header: 'Ratings', accessorKey: 'currentRatingsMean' },
			{ header: 'New Sites p.a.', accessorKey: 'avgNewSitesPerYear' },
			{ header: 'Sites 3-Year CAGR', accessorKey: 'sitesCAGRDisplay' },
			{ header: 'New Local Authorities p.a.', accessorKey: 'avgNewLasPerYear' },
			{ header: 'New Beds p.a.', accessorKey: 'avgNewBedsPerYear' },
			{ header: 'Beds 3-Year CAGR', accessorKey: 'bedsCAGRDisplay' },
		],
		[name],
	)

	const formattedData: RowData[] = operators.map((op) => {
		const operatorData: OperatorData = {
			id: op.id,
			name: op.name,
			operatorGroupName: op.operatorGroupName,
			sites: op.sites,
		}
		return calculateOperatorMetrics(operatorData, code)
	})

	const handleRowClick = (id: string) => {
		navigate(`/operators/${id}`)
	}

	const onCell = (cellContext: CellContext<RowData, unknown>) => {
		const cellValue = cellContext.getValue()
		if (cellValue === null || cellValue === undefined) {
			return <Paragraph>-</Paragraph>
		}

		return <Paragraph>{flexRender(cellContext.column.columnDef.cell, cellContext)}</Paragraph>
	}

	return <DataTable columns={columns} data={formattedData as RowData[]} onCell={onCell} onRowClick={handleRowClick} />
}

export { OperatorsTable }
