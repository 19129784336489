/**
 * @generated SignedSource<<99d58eaa0d2f376f210ff77fdc86fd5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareSupplyRateQuickFactByCaFragment$data = {
  readonly stats: ReadonlyArray<{
    readonly careSupplyRate: number | null | undefined;
    readonly code: string;
    readonly higherRateAllowance: number;
    readonly numberOfBeds: number;
    readonly type: GeographyTypes;
  }>;
  readonly " $fragmentType": "ElderlyCareSupplyRateQuickFactByCaFragment";
};
export type ElderlyCareSupplyRateQuickFactByCaFragment$key = {
  readonly " $data"?: ElderlyCareSupplyRateQuickFactByCaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareSupplyRateQuickFactByCaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyCareSupplyRateQuickFactByCaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElderlyCareData",
      "kind": "LinkedField",
      "name": "stats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "careSupplyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "higherRateAllowance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElderlyCareStats",
  "abstractKey": null
};

(node as any).hash = "33062c5ba7ee73a9c933c8912b766034";

export default node;
