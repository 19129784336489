import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { RadiusValues, SitesByCaQuery } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCa } from '../../graphql/queries/SitesByCa'
import { ElderlySitesChartSiteFragment$key } from './__generated__/ElderlySitesChartSiteFragment.graphql'
import { ElderlySitesChart } from './ElderlySitesChart'
import { ElderlySitesChartSiteFragment } from './ElderlySitesChartSiteFragment'
import { getElderlySites } from './helpers'

interface CaElderlySitesChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlySitesChart: React.FC<CaElderlySitesChartProps> = ({ oaCode, radius }) => {
	const sitesData = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, {
		oaCode,
		radius,
	})

	const sites = useFragment<ElderlySitesChartSiteFragment$key>(ElderlySitesChartSiteFragment, sitesData.sitesByCa.sites)

	const model = getElderlySites(sites)

	return <ElderlySitesChart model={model} />
}

export { CaElderlySitesChart }
