import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	AAUniversalCreditPopulationRatiosByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/AAUniversalCreditPopulationRatiosByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { AAUniversalCreditPopulationRatiosByCa } from '../../graphql/queries/AAUniversalCreditPopulationRatiosByCa'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { Model } from '../../shared/charts/types'
import { HouseholdsWithUniversalCreditWorkingPopulationRatio } from './HouseholdsWithUniversalCreditWorkingPopulationChart'

interface CaUniversalCreditWorkingPopChartProps {
	oaCode: string
	radius: RadiusValues
}
const CaHouseholdsWithUniversalCreditWorkingPopulationChart: React.FC<CaUniversalCreditWorkingPopChartProps> = ({
	oaCode,
	radius,
}) => {
	const { aaUniversalCreditPopulationRatiosByCa: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<AAUniversalCreditPopulationRatiosByCaQuery>(AAUniversalCreditPopulationRatiosByCa, {
			oaCode,
			radius,
		})

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: [...d.values],
		})),
	}

	return <HouseholdsWithUniversalCreditWorkingPopulationRatio model={model} />
}

export { CaHouseholdsWithUniversalCreditWorkingPopulationChart }
