import graphql from 'babel-plugin-relay/macro'

export const OutputAreasByMileRadius = graphql`
	query OutputAreasByMileRadiusQuery($long: String!, $lat: String!, $radius: RadiusValues!) {
		outputAreasByMileRadius(long: $long, lat: $lat, radius: $radius) {
			...MapCaptureAreaFragment
			...CareHomesCatchmentAreaFragment
		}
	}
`
