/**
 * @generated SignedSource<<cf2a8689a373c04b14dc469eb120f71c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RadiusValues = "FIVE" | "ONE" | "TEN" | "THREE" | "%future added value";
export type OperatorsByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type OperatorsByCaQuery$data = {
  readonly operatorsByCa: {
    readonly operators: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OperatorsTableFragment">;
    }>;
  };
};
export type OperatorsByCaQuery = {
  response: OperatorsByCaQuery$data;
  variables: OperatorsByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "oaCode",
    "variableName": "oaCode"
  },
  {
    "kind": "Variable",
    "name": "radius",
    "variableName": "radius"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorsByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Operators",
        "kind": "LinkedField",
        "name": "operatorsByCa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Operator",
            "kind": "LinkedField",
            "name": "operators",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OperatorsTableFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OperatorsByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Operators",
        "kind": "LinkedField",
        "name": "operatorsByCa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Operator",
            "kind": "LinkedField",
            "name": "operators",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "operatorGroupName",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "sites",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "careHome",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentRatings",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ladCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfBeds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "servicesProviders",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "specialisms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regionCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "706903a40962583dfdee4a2c287f0e3c",
    "id": null,
    "metadata": {},
    "name": "OperatorsByCaQuery",
    "operationKind": "query",
    "text": "query OperatorsByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  operatorsByCa(oaCode: $oaCode, radius: $radius) {\n    operators {\n      ...OperatorsTableFragment\n    }\n  }\n}\n\nfragment OperatorsTableFragment on Operator {\n  id\n  name\n  operatorGroupName\n  registrationDate\n  serviceTypes\n  website\n  sites {\n    id\n    careHome\n    currentRatings\n    registrationDate\n    ladCode\n    numberOfBeds\n    servicesProviders\n    specialisms\n    regionCode\n  }\n}\n"
  }
};
})();

(node as any).hash = "33856189750561d8c494f83cf263296c";

export default node;
