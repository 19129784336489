import graphql from 'babel-plugin-relay/macro'

export const OperatorGroups = graphql`
	query OperatorGroupsQuery($search: String) {
		operatorGroups(search: $search) {
			id
			name
			operators {
				id
				name
			}
		}
	}
`
