import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCodeQuery } from '../../../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { IndustryDistributionsByLadCodeQuery } from '../../../../graphql/queries/__generated__/IndustryDistributionsByLadCodeQuery.graphql'
import { UnemploymentRatesByLadCodeQuery } from '../../../../graphql/queries/__generated__/UnemploymentRatesByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByLadCode } from '../../../../graphql/queries/GrossDisposableHouseholdIncomesByLadCode'
import { IndustryDistributionsByLadCode } from '../../../../graphql/queries/IndustryDistributionsByLad'
import { UnemploymentRatesByLadCode } from '../../../../graphql/queries/UnemploymentRatesByLad'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { Model } from '../../../../shared/charts/types'
import { TextPlaceholder } from '../../../../shared/placeholder/Placeholder'
import { transformToMarkdownTable } from '../../../../utils/transformToMarkdownTable'

interface AIEconomicSummaryProps {
	ladCode: string
}

const AIEconomicSummary: React.FC<AIEconomicSummaryProps> = ({ ladCode }) => {
	const { unemploymentRatesByLadCode: { labels: unemploymentLabels = [], datasets: unemploymentDatasets = [] } = {} } =
		useLazyLoadQuery<UnemploymentRatesByLadCodeQuery>(UnemploymentRatesByLadCode, { ladCode })

	const { grossDisposableHouseholdIncomesByLadCode: { labels: gdhiLabels = [], datasets: gdhiDatasets = [] } = {} } =
		useLazyLoadQuery<GrossDisposableHouseholdIncomesByLadCodeQuery>(GrossDisposableHouseholdIncomesByLadCode, {
			ladCode,
		})

	const { industryDistributionsByLadCode: { labels: industryLabels = [], datasets: industryDatasets = [] } = {} } =
		useLazyLoadQuery<IndustryDistributionsByLadCodeQuery>(IndustryDistributionsByLadCode, {
			ladCode,
		})

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes: unemploymentDatasets.map((d) => d.code),
	})

	const industryDatasetsProcessed = industryDatasets.map((d) => {
		return d.values.map((v, i) => ({
			value: v,
			industry: industryLabels[i],
		}))
	})

	const sortedLad = [...industryDatasetsProcessed[0]].sort((a, b) => b.value - a.value)
	const sortedIndustries = sortedLad.map((item) => item.industry)
	const sortedDatasetValues = industryDatasetsProcessed.map((dataset) => {
		const sorted = sortedIndustries.map((industry) => {
			return dataset.find((item) => item.industry === industry)
		})
		return sorted.map((item) => item?.value || 0)
	})

	const industryModel: Model = {
		labels: sortedIndustries,
		datasets: industryDatasets.map((d, i) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: sortedDatasetValues[i],
		})),
	}

	const unemploymentModel: Model = {
		labels: [...unemploymentLabels],
		datasets: unemploymentDatasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	const gdhiModel: Model = {
		labels: [...gdhiLabels],
		datasets: gdhiDatasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	const catchmentGeographies = geos.map((geo) => ({ code: geo.code, name: geo.name }))
	const gdhiFormatted = transformToMarkdownTable(gdhiModel)
	const unemploymentFormatted = transformToMarkdownTable(unemploymentModel)
	const industryFormatted = transformToMarkdownTable(industryModel)

	const prompt = `
You are a data analyst, analyze the economic situation of the catchment area, comparing it to its Region and England based solely on the data provided below.

Data:
1. Catchment area: ${JSON.stringify(catchmentGeographies)}
2. Gross Disposable Household Income:
where 
| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(gdhiFormatted)}
3. Unemployment Rate:
| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(unemploymentFormatted)}
4. Industry Sizes by share of workforce:
| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(industryFormatted)}

**Instructions:**

Structure Your Response Without Labels:
- Start directly with a concise headline (maximum 30 characters) summarizing the main finding. Do not include any label words here (e.g., do not start the sentence with something like "Headline:" or use quotes).
- Follow with a paragraph of 90-200 characters that accurately reflects your analysis. Do not include any labels or introductory words.
`

	const iteration = 23
	const key = `economic-summary-${ladCode}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIEconomicSummary: React.FC<AIEconomicSummaryProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<AIEconomicSummary ladCode={ladCode} />
		</React.Suspense>
	)
}

export { SuspendedAIEconomicSummary as AIEconomicSummary }
