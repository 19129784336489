import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCode } from '../../graphql/queries/SitesByLadCode'
import { ElderlySitesChartSiteFragment$key } from './__generated__/ElderlySitesChartSiteFragment.graphql'
import { ElderlySitesChart } from './ElderlySitesChart'
import { ElderlySitesChartSiteFragment } from './ElderlySitesChartSiteFragment'
import { getElderlySites } from './helpers'

interface LadElderlySitesChartProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const LadElderlySitesChart: React.FC<LadElderlySitesChartProps> = ({ ladCode }) => {
	const sitesData = useLazyLoadQuery<SitesByLadCodeQuery>(SitesByLadCode, {
		ladCode,
	})

	const sites = useFragment<ElderlySitesChartSiteFragment$key>(
		ElderlySitesChartSiteFragment,
		sitesData.sitesByLadCode.sites,
	)

	const model = getElderlySites(sites)

	return <ElderlySitesChart model={model} />
}

export { LadElderlySitesChart }
