import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareStatsByCa = graphql`
	query ElderlyCareStatsByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		elderlyCareStatsByCa(oaCode: $oaCode, radius: $radius) {
			...ElderlyCareSupplyRateQuickFactFragment
			...ElderlyCareSupplyRateQuickFactByCaFragment
		}
	}
`
