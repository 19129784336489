import './placeholder.css'

import React from 'react'

interface TextPlaceholderProps {
	height?: string | number
}

const TextPlaceholder: React.FC<TextPlaceholderProps> = ({ height = '80px' }) => (
	<div className="text-placeholder" style={{ height }}></div>
)

type HeadingProps = {
	level: 1 | 2 | 3 | 4 | 5 | 6
}

const HeadingMap: Record<number, number> = {
	1: 50,
	2: 40,
	3: 30,
	4: 20,
	5: 18,
	6: 16,
}

const HeadingPlaceholder: React.FC<HeadingProps> = ({ level }) => {
	const height = HeadingMap[level]
	return <TextPlaceholder height={height} />
}

export { HeadingPlaceholder, TextPlaceholder }
