import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { HourlyWagesByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { HourlyWagesByCa } from '../../graphql/queries/HourlyWagesByCa'
import { Model } from '../../shared/charts/types'
import { HourlyWagesChart } from './HourlyWagesChart'

interface CaHourlyWagesChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaHourlyWagesChart: React.FC<CaHourlyWagesChartProps> = ({ oaCode, radius }) => {
	const { hourlyWagesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<HourlyWagesByCaQuery>(
		HourlyWagesByCa,
		{ oaCode, radius },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: [...d.values],
		})),
	}

	return <HourlyWagesChart model={model} />
}

export { CaHourlyWagesChart }
