import graphql from 'babel-plugin-relay/macro'

export const OperatorsOperatorFragment = graphql`
	fragment OperatorsOperatorFragment on Operator {
		id
		constituency
		name
		serviceTypes
		operatorGroupId
		operatorGroupName
		sites {
			id
			ladCode
			name
			odsCode
			onspdLatitude
			onspdLongitude
			registrationDate
			sector
			serviceTypes
			website
			currentRatings
			numberOfBeds
			regionCode
			servicesProviders
			specialisms
		}
	}
`
