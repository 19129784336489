import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { HousePricesByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { HousePricesByCa } from '../../graphql/queries/HousePricesByCa'
import { Model } from '../../shared/charts/types'
import { HousePricesChart } from './HousePricesChart'

interface CaHousePricesChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaHousePricesChart: React.FC<CaHousePricesChartProps> = ({ oaCode, radius }) => {
	const { housePricesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<HousePricesByCaQuery>(
		HousePricesByCa,
		{ oaCode, radius },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: [...d.values],
		})),
	}

	return <HousePricesChart model={model} />
}

export { CaHousePricesChart }
