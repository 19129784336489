import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import {
	AADisablingConditionsByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/AADisablingConditionsByCaQuery.graphql'
import { PopulationByCaQuery } from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { AADisablingConditionsByCa } from '../../graphql/queries/AADisablingConditionsByCa'
import { PopulationByCa } from '../../graphql/queries/PopulationByCa'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface DementiaPrevalenceQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const DementiaPrevalenceByCa: React.FC<DementiaPrevalenceQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 65,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const dementiaData = useLazyLoadQuery<AADisablingConditionsByCaQuery>(AADisablingConditionsByCa, { oaCode, radius })
	const { labels: dementiaLabels, datasets: dementiaDatasets } = dementiaData.aaDisablingConditionsByCa

	const dementiaIndex = dementiaLabels.length - 1
	const dementiaCa = dementiaDatasets[0].values[dementiaIndex]
	const dementiaRegion = dementiaDatasets[dementiaDatasets.length - 2].values[dementiaIndex]
	const dementiaNational = dementiaDatasets[dementiaDatasets.length - 1].values[dementiaIndex]

	const popCa = pop.geographies[0].ageRanges[0].values[0]
	const popRegion = pop.geographies[pop.geographies.length - 2].ageRanges[0].values[0]
	const popNational = pop.geographies[pop.geographies.length - 1].ageRanges[0].values[0]

	const dementiaPrevalenceCa = (dementiaCa / popCa) * 100
	const dementiaPrevalenceRegion = (dementiaRegion / popRegion) * 100
	const dementiaPrevalenceNational = (dementiaNational / popNational) * 100
	const comparison = compareRegion ? dementiaPrevalenceRegion : dementiaPrevalenceNational

	return (
		<QuickFact
			heading="Dementia Prevalence"
			value={formatPercentage(dementiaPrevalenceCa, 0, 1)}
			comparison={formatPercentage(comparison, 0, 1)}
			isComparable={true}
			reverseComparison={true}
		/>
	)
}

const Suspended: React.FC<DementiaPrevalenceQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<DementiaPrevalenceByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as DementiaPrevalenceByCa }
