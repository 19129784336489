import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery } from '../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { LocalAuthorityByLadCode } from '../graphql/queries/LocalAuthorityByLad'
import { theme } from '../Theme'
import { TextPlaceholder } from './placeholder/Placeholder'
import { ColoredBold, Heading } from './Text'

interface LadHeaderProps {
	ladCode: string
}

const LadHeader: React.FC<LadHeaderProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LocalAuthorityByLadCodeQuery>(LocalAuthorityByLadCode, {
		ladCode,
	})

	const title = la.name
	const subTitle = `${la.regionName}, ${la.countryName}`

	return (
		<>
			<Heading level={1}>{title}</Heading>
			<ColoredBold color={theme.colors.grey}>{`${subTitle}`} </ColoredBold>
		</>
	)
}

const Suspended: React.FC<LadHeaderProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<LadHeader ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as LadHeader }
