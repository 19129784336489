/**
 * @generated SignedSource<<28d77669d57b821dfedb61847e6109b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlySitesChartSiteFragment$data = ReadonlyArray<{
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlySitesChartSiteFragment";
}>;
export type ElderlySitesChartSiteFragment$key = ReadonlyArray<{
  readonly " $data"?: ElderlySitesChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlySitesChartSiteFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ElderlySitesChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "bd4a7c2c3470f2b37d0a8db7e2e70ba2";

export default node;
