import React from 'react'

interface ButtonProps {
	onClick: () => void
	disabled?: boolean
	children?: React.ReactNode
	icon?: React.ReactNode
}

const PrimaryButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-primary fw-bold">
		{children}
	</BaseButton>
)

const PrimaryOutlineButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-outline-primary fw-bold">
		{children}
	</BaseButton>
)

const SecondaryButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-secondary align-middle fw-bold color">
		{children}
	</BaseButton>
)

const SecondaryOutlineButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-outline-secondary fw-bold">
		{children}
	</BaseButton>
)

const DangerButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-danger fw-bold color">
		{children}
	</BaseButton>
)

const LinkButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-link fw-medium">
		{children}
	</BaseButton>
)

interface BaseButtonProps extends ButtonProps {
	className?: string
	style?: React.CSSProperties
}

const BaseButton: React.FC<BaseButtonProps> = ({ icon, onClick, disabled, children, className = '', style }) => {
	const classes = `btn ${className}`
	const spanClassName = children ? '' : 'me-2'
	const iconStyle: React.CSSProperties = {
		position: 'relative',
		height: 20,
	}

	return (
		<button onClick={onClick} disabled={disabled} className={classes} style={style}>
			{icon && (
				<span style={iconStyle} className={spanClassName}>
					{icon}
				</span>
			)}
			{children}
		</button>
	)
}

const TextButton: React.FC<ButtonProps> = ({ onClick, disabled, children }) => (
	<button onClick={onClick} disabled={disabled} className="btn btn-link fw-bold fs-6">
		{children}
	</button>
)

export {
	BaseButton,
	DangerButton,
	LinkButton,
	PrimaryButton,
	PrimaryOutlineButton,
	SecondaryButton,
	SecondaryOutlineButton,
	TextButton,
}
