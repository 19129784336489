/**
 * @generated SignedSource<<d3b7428487fa541492d7660285557f8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteByIdQuery$variables = {
  id: string;
};
export type SiteByIdQuery$data = {
  readonly siteById: {
    readonly careHome: string | null | undefined;
    readonly constituency: string | null | undefined;
    readonly cqcLocationId: string;
    readonly cqcProviderId: string;
    readonly createdAt: any;
    readonly currentRatings: any | null | undefined;
    readonly dormancy: string | null | undefined;
    readonly emails: string | null | undefined;
    readonly employees: string | null | undefined;
    readonly id: any;
    readonly inspectionDirectorate: string | null | undefined;
    readonly ladCode: string | null | undefined;
    readonly lastInspectionDate: any | null | undefined;
    readonly lastReportPublicationDate: any | null | undefined;
    readonly linkedinId: string | null | undefined;
    readonly location: string | null | undefined;
    readonly locationTypes: string | null | undefined;
    readonly mainPhoneNumber: string | null | undefined;
    readonly name: string;
    readonly numberOfBeds: number | null | undefined;
    readonly odsCode: string | null | undefined;
    readonly onspdCcgCode: string | null | undefined;
    readonly onspdCcgName: string | null | undefined;
    readonly onspdIcbCode: string | null | undefined;
    readonly onspdIcbName: string | null | undefined;
    readonly onspdLatitude: number | null | undefined;
    readonly onspdLongitude: number | null | undefined;
    readonly organisationType: string | null | undefined;
    readonly phoneNumber: string | null | undefined;
    readonly postalAddressLine1: string | null | undefined;
    readonly postalAddressTownCity: string;
    readonly postalCode: string;
    readonly regionCode: string | null | undefined;
    readonly registrationDate: any | null | undefined;
    readonly registrationStatus: string | null | undefined;
    readonly relationships: string | null | undefined;
    readonly sector: string | null | undefined;
    readonly serviceTypes: string | null | undefined;
    readonly servicesProviders: string | null | undefined;
    readonly source: string;
    readonly specialisms: any | null | undefined;
    readonly type: string;
    readonly updatedAt: any;
    readonly uprn: string | null | undefined;
    readonly website: string | null | undefined;
  };
};
export type SiteByIdQuery = {
  response: SiteByIdQuery$data;
  variables: SiteByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Site",
    "kind": "LinkedField",
    "name": "siteById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "careHome",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "constituency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cqcLocationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentRatings",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organisationType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cqcProviderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dormancy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emails",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employees",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inspectionDirectorate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastInspectionDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastReportPublicationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "linkedinId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mainPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfBeds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "odsCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdCcgCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdCcgName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdIcbCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdIcbName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdLatitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdLongitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressLine1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressTownCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relationships",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "servicesProviders",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sector",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "source",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "specialisms",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uprn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "website",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "edaf2991eb668b01b201642ac3c088c1",
    "id": null,
    "metadata": {},
    "name": "SiteByIdQuery",
    "operationKind": "query",
    "text": "query SiteByIdQuery(\n  $id: ID!\n) {\n  siteById(id: $id) {\n    id\n    careHome\n    constituency\n    cqcLocationId\n    createdAt\n    currentRatings\n    organisationType\n    cqcProviderId\n    dormancy\n    emails\n    employees\n    inspectionDirectorate\n    lastInspectionDate\n    lastReportPublicationDate\n    linkedinId\n    location\n    ladCode\n    locationTypes\n    mainPhoneNumber\n    name\n    numberOfBeds\n    odsCode\n    onspdCcgCode\n    onspdCcgName\n    onspdIcbCode\n    onspdIcbName\n    onspdLatitude\n    onspdLongitude\n    phoneNumber\n    postalAddressLine1\n    postalAddressTownCity\n    postalCode\n    regionCode\n    registrationDate\n    registrationStatus\n    relationships\n    servicesProviders\n    sector\n    serviceTypes\n    source\n    specialisms\n    type\n    updatedAt\n    uprn\n    website\n  }\n}\n"
  }
};
})();

(node as any).hash = "48d068834702cd62f34b4397e68c1ac9";

export default node;
