/**
 * @generated SignedSource<<68789fee9e15c5efd41cd9c175b5d3ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesByLadCodeQuery$variables = {
  ladCode: string;
};
export type SitesByLadCodeQuery$data = {
  readonly sitesByLadCode: {
    readonly sites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"CareHomesSitesByLadFragment" | "ElderlyBedShortagesForecastChartSiteFragment" | "ElderlyBedsTimeSeriesChartSiteFragment" | "ElderlyCareSupplyRateChartSiteFragment" | "ElderlySitesChartSiteFragment" | "SitesTableFragment">;
    }>;
  };
};
export type SitesByLadCodeQuery = {
  response: SitesByLadCodeQuery$data;
  variables: SitesByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ladCode",
    "variableName": "ladCode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SitesByLadCodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sites",
        "kind": "LinkedField",
        "name": "sitesByLadCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CareHomesSitesByLadFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SitesTableFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlyBedShortagesForecastChartSiteFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlySitesChartSiteFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlyCareSupplyRateChartSiteFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlyBedsTimeSeriesChartSiteFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SitesByLadCodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sites",
        "kind": "LinkedField",
        "name": "sitesByLadCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialisms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalAddressTownCity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "operatorName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBeds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentRatings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9710239b828ee8aec954ca63a78535d3",
    "id": null,
    "metadata": {},
    "name": "SitesByLadCodeQuery",
    "operationKind": "query",
    "text": "query SitesByLadCodeQuery(\n  $ladCode: String!\n) {\n  sitesByLadCode(ladCode: $ladCode) {\n    sites {\n      ...CareHomesSitesByLadFragment\n      ...SitesTableFragment\n      ...ElderlyBedShortagesForecastChartSiteFragment\n      ...ElderlySitesChartSiteFragment\n      ...ElderlyCareSupplyRateChartSiteFragment\n      ...ElderlyBedsTimeSeriesChartSiteFragment\n    }\n  }\n}\n\nfragment CareHomesSitesByLadFragment on Site {\n  id\n  name\n  location\n  specialisms\n}\n\nfragment ElderlyBedShortagesForecastChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n  specialisms\n}\n\nfragment ElderlyBedsTimeSeriesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n  specialisms\n}\n\nfragment ElderlyCareSupplyRateChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlySitesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment SitesTableFragment on Site {\n  id\n  name\n  postalAddressTownCity\n  operatorName\n  numberOfBeds\n  registrationDate\n  currentRatings\n  specialisms\n  serviceTypes\n  website\n}\n"
  }
};
})();

(node as any).hash = "bc1ded0bea391ae842914f608b626b94";

export default node;
