import graphql from 'babel-plugin-relay/macro'

export const AADisablingConditionsByCa = graphql`
	query AADisablingConditionsByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		aaDisablingConditionsByCa(oaCode: $oaCode, radius: $radius) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
