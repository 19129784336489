import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { PopulationByCaQuery } from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { PopulationByCa } from '../../graphql/queries/PopulationByCa'
import { Model } from '../../shared/charts/types'
import { PopulationGrowthByAgeGroupChart } from './PopulationGrowthByAgeGroupChart'

interface CaPopulationGrowthByAgeGroupChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaPopulationGrowthByAgeGroupChart: React.FC<CaPopulationGrowthByAgeGroupChartProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 20,
		minAge: 0,
		maxAge: 100,
		futureOffset: 5,
		includeIntermediates: false,
	})

	const labels = pop.geographies[0].ageRanges.map((ageRange) => ageRange.ageRange)
	const currentPopulations = pop.geographies[0].ageRanges.map((ageRange) => ageRange.values[0])
	const futurePopulations = pop.geographies[0].ageRanges.map((ageRange) => ageRange.values[ageRange.values.length - 1])

	const model: Model = {
		labels,
		datasets: [
			{
				id: `${pop.years[0]}`,
				values: currentPopulations,
			},
			{
				id: `${pop.years[1]}`,
				values: futurePopulations,
			},
		],
	}

	return <PopulationGrowthByAgeGroupChart model={model} />
}

export { CaPopulationGrowthByAgeGroupChart }
