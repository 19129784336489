import graphql from 'babel-plugin-relay/macro'

export const CareHomesCatchmentAreaFragment = graphql`
	fragment CareHomesCatchmentAreaFragment on CatchmentArea {
		ladCodes
		outputAreas {
			geometry
		}
	}
`
