import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { ElderlyCareStatsByLadCodeQuery } from '../../graphql/queries/__generated__/ElderlyCareStatsByLadCodeQuery.graphql'
import { ElderlyCareStatsByLadCode } from '../../graphql/queries/ElderlyCareStatsByLad'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'
import { ElderlyCareSupplyRateQuickFactFragment$key } from './__generated__/ElderlyCareSupplyRateQuickFactFragment.graphql'
import { ElderlyCareSupplyRateQuickFactFragment } from './ElderlyCareSupplyRateQuickFactFragment'

interface ElderlyCareSupplyRateFactProps {
	ladCode: string
	compareRegion?: boolean
}

const ElderlyCareSupplyRateByLad: React.FC<ElderlyCareSupplyRateFactProps> = ({ ladCode, compareRegion }) => {
	const ecsData = useLazyLoadQuery<ElderlyCareStatsByLadCodeQuery>(ElderlyCareStatsByLadCode, {
		ladCode: ladCode,
	})

	const stats = useFragment<ElderlyCareSupplyRateQuickFactFragment$key>(
		ElderlyCareSupplyRateQuickFactFragment,
		ecsData.elderlyCareStatsByLadCode,
	)

	const supplyRateLad = stats.stats[0].careSupplyRate || 0
	const supplyRateRegion = stats.stats[1].careSupplyRate || 0
	const supplyRateNational = stats.stats[2].careSupplyRate || 0

	const comparison = compareRegion ? supplyRateRegion : supplyRateNational

	return (
		<QuickFact
			heading="Care Bed Supply Rate"
			value={formatPercentage(supplyRateLad * 100, 0, 0)}
			comparison={formatPercentage(comparison * 100, 0, 1)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<ElderlyCareSupplyRateFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareSupplyRateByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareSupplyRateByLad }
