/**
 * @generated SignedSource<<1a880814dd6b414a90b1ddcde4c4cd01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
export type ElderlyCareStatsByTypesQuery$variables = {
  types: ReadonlyArray<GeographyTypes>;
};
export type ElderlyCareStatsByTypesQuery$data = {
  readonly elderlyCareStatsByTypes: {
    readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareStatsCaFragment" | "ElderlyCareStatsFragment" | "LaTableElderlyCareStatsFragment">;
  };
};
export type ElderlyCareStatsByTypesQuery = {
  response: ElderlyCareStatsByTypesQuery$data;
  variables: ElderlyCareStatsByTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "types"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "types"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ElderlyCareStatsByTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ElderlyCareStats",
        "kind": "LinkedField",
        "name": "elderlyCareStatsByTypes",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareStatsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LaTableElderlyCareStatsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareStatsCaFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ElderlyCareStatsByTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ElderlyCareStats",
        "kind": "LinkedField",
        "name": "elderlyCareStatsByTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElderlyCareData",
            "kind": "LinkedField",
            "name": "stats",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "populationOver65",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBeds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9e16807f78a3bdfe6cad5e70f55085f",
    "id": null,
    "metadata": {},
    "name": "ElderlyCareStatsByTypesQuery",
    "operationKind": "query",
    "text": "query ElderlyCareStatsByTypesQuery(\n  $types: [GeographyTypes!]!\n) {\n  elderlyCareStatsByTypes(types: $types) {\n    ...ElderlyCareStatsFragment\n    ...LaTableElderlyCareStatsFragment\n    ...ElderlyCareStatsCaFragment\n  }\n}\n\nfragment ElderlyCareStatsCaFragment on ElderlyCareStats {\n  stats {\n    code\n    populationOver65\n    numberOfBeds\n  }\n}\n\nfragment ElderlyCareStatsFragment on ElderlyCareStats {\n  stats {\n    code\n    populationOver65\n    numberOfBeds\n  }\n}\n\nfragment LaTableElderlyCareStatsFragment on ElderlyCareStats {\n  stats {\n    code\n    populationOver65\n    numberOfBeds\n  }\n}\n"
  }
};
})();

(node as any).hash = "61d6e48469975f0640232ad9353364e0";

export default node;
