import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCode } from '../../graphql/queries/SitesByLadCode'
import { ElderlyBedsTimeSeriesChartSiteFragment$key } from './__generated__/ElderlyBedsTimeSeriesChartSiteFragment.graphql'
import { ElderlyBedsTimeSeriesChart } from './ElderlyBedsTimeSeriesChart'
import { ElderlyBedsTimeSeriesChartSiteFragment } from './ElderlyBedsTimeSeriesChartSiteFragment'
import { getElderlyBeds } from './helpers'

interface ElderlyBedsTimeSeriesChartProps {
	ladCode: string
}

const LadElderlyBedsTimeSeriesChart: React.FC<ElderlyBedsTimeSeriesChartProps> = ({ ladCode }) => {
	const sitesData = useLazyLoadQuery<SitesByLadCodeQuery>(SitesByLadCode, {
		ladCode: ladCode,
	})

	const sites = useFragment<ElderlyBedsTimeSeriesChartSiteFragment$key>(
		ElderlyBedsTimeSeriesChartSiteFragment,
		sitesData.sitesByLadCode.sites,
	)

	const model = getElderlyBeds(sites)

	return <ElderlyBedsTimeSeriesChart model={model} />
}

export { LadElderlyBedsTimeSeriesChart }
