import { RadiusValues } from './graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'

const currentYear = 2024

const elderlySpecialismKey = 'Caring for adults over 65 yrs'

const defaultCa: {
	lat: number
	long: number
	radius: RadiusValues
} = {
	lat: 51.54185,
	long: 0.078038,
	radius: 'ONE',
}

const siteRatingPoints = {
	Outstanding: 3,
	Good: 2,
	'Requires improvement': 1,
	Inadequate: 0,
}

const milesRadiusValueInMeters: Record<RadiusValues, number> = {
	ONE: 1609,
	THREE: 4828,
	FIVE: 8046,
	TEN: 16093,
	'%future added value': 0, // this is because of the generated keys
}

enum GeographyTypes {
	lad = 'lad',
	region = 'region',
	country = 'country',
}
const awardTypesLabelMap: { [key: string]: string } = { lowerRate: 'Part-Time', higherRate: 'Full-time' }

export {
	awardTypesLabelMap,
	currentYear,
	defaultCa,
	elderlySpecialismKey,
	GeographyTypes,
	milesRadiusValueInMeters,
	siteRatingPoints,
}
