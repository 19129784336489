import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import {
	ElderlyCareStatsByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/ElderlyCareStatsByCaQuery.graphql'
import { ElderlyCareStatsByCa } from '../../graphql/queries/ElderlyCareStatsByCa'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'
import { ElderlyCareSupplyRateQuickFactByCaFragment$key } from './__generated__/ElderlyCareSupplyRateQuickFactByCaFragment.graphql'
import { ElderlyCareSupplyRateQuickFactByCaFragment } from './ElderlyCareSupplyRateQuickFactByCaFragment'

interface ElderlyBedShortagesFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const ElderlyCareBedShortagesByCaByCareStats: React.FC<ElderlyBedShortagesFactProps> = ({
	oaCode,
	radius,
	compareRegion,
}) => {
	const ecsData = useLazyLoadQuery<ElderlyCareStatsByCaQuery>(ElderlyCareStatsByCa, {
		oaCode,
		radius,
	})

	const stats = useFragment<ElderlyCareSupplyRateQuickFactByCaFragment$key>(
		ElderlyCareSupplyRateQuickFactByCaFragment,
		ecsData.elderlyCareStatsByCa,
	)

	const bedShortagesCa = (stats.stats[0].numberOfBeds || 0) - (stats.stats[0].higherRateAllowance || 0)
	const bedShortageRegion =
		(stats.stats[stats.stats.length - 2].numberOfBeds || 0) -
		(stats.stats[stats.stats.length - 2].higherRateAllowance || 0)
	const bedShortageNational =
		(stats.stats[stats.stats.length - 1].numberOfBeds || 0) -
		(stats.stats[stats.stats.length - 1].higherRateAllowance || 0)

	const comparison = compareRegion ? bedShortageRegion : bedShortageNational

	return (
		<QuickFact
			heading="Bed Shortage"
			value={formatNumber(bedShortagesCa, 0, 0)}
			comparison={formatNumber(comparison, 0, 0)}
			isComparable={true}
			valueColoringNegativeVsPositive={true}
		/>
	)
}

const Suspended: React.FC<ElderlyBedShortagesFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareBedShortagesByCaByCareStats {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareBedShortagesByCa }
