import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { CareFeesByLadCodeQuery as Response } from '../../graphql/queries/__generated__/CareFeesByLadCodeQuery.graphql'
import { CareFeesByLadCode as Request } from '../../graphql/queries/CareFeesByLad'
import { formatCurrency } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'
import { CareFeesQuickFactFragment$key } from './__generated__/CareFeesQuickFactFragment.graphql'
import { CareFeesQuickFactFragment } from './CareFeesQuickFactFragment'

interface CareFeeQuickFactProps {
	ladCode: string
}

const CareFeeByLad: React.FC<CareFeeQuickFactProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<Response>(Request, { ladCode })
	const { labels, datasets } = useFragment<CareFeesQuickFactFragment$key>(
		CareFeesQuickFactFragment,
		data.careFeeRatesByLadCode,
	)

	const index = labels.findIndex((label) => label === 'avg_weekly_over_65_with_nursing')
	const avgWeeklyWithNursing = datasets[0].values[index]

	const nationalAvgWeeklyWithNursing = datasets[1].values[index]

	return (
		<QuickFact
			heading="Weekly Elderly Care Fees"
			value={formatCurrency(avgWeeklyWithNursing, 0)}
			comparison={`Nat.Avg: ${formatCurrency(nationalAvgWeeklyWithNursing, 0)}`}
			isComparable={true}
			reverseComparison={false}
		/>
	)
}

const Suspended: React.FC<CareFeeQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<CareFeeByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as CareFeeByLad }
