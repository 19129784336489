import React from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import {
	ProjectsByUserIdQuery,
	ProjectsByUserIdQuery$data,
} from '../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { ProjectsByUserId } from '../../graphql/queries/ProjectsByUserId'
import { Page } from '../../layout/page/Page'
import { SecondaryButton } from '../../shared/Button'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { Heading, PageTitle, Paragraph } from '../../shared/Text'

const Projects: React.FC = () => {
	const navigate = useNavigate()
	const onClick = (id: string) => {
		navigate(`/report/${id}`)
	}

	const data = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { userId: '1' })
	const projects = data.projectsByUserId?.projects || []
	const projectList = projects.map((project) => <Project key={project.id} project={project} onClick={onClick} />)

	return (
		<Page title="Projects">
			<Container>
				<Row>
					<Col count={3}></Col>
					<Col count={6}>
						<Panel height="auto">
							<PageTitle>Projects</PageTitle>

							<div className="list-group">{projectList}</div>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

interface Project {
	project: ProjectsByUserIdQuery$data['projectsByUserId']['projects'][0]
	onClick: (id: string) => void
}

const Project: React.FC<Project> = ({ project, onClick }) => {
	return (
		<div className="list-group-item" key={project.id}>
			<div className="p-3">
				<Heading level={4}>ID: {project.id}</Heading>
				<Paragraph>{project.client}</Paragraph>
				<Paragraph>
					{project.address}, {project.townCity}, {project.postalCode}
				</Paragraph>
				<Paragraph>{project.titleNumbers.join(', ')}</Paragraph>
				<SecondaryButton onClick={() => onClick(project.id)}>MVU</SecondaryButton>
			</div>
		</div>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Projects />
		</React.Suspense>
	)
}

export { Suspended as Projects }
