import graphql from 'babel-plugin-relay/macro'

export const AAUniversalCreditsByLadCode = graphql`
	query AAUniversalCreditsByLadCodeQuery($ladCode: String!) {
		aaUniversalCreditsByLadCode(ladCode: $ladCode) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
