import graphql from 'babel-plugin-relay/macro'

export const SiteById = graphql`
	query SiteByIdQuery($id: ID!) {
		siteById(id: $id) {
			id
			careHome
			constituency
			cqcLocationId
			createdAt
			currentRatings
			organisationType
			cqcProviderId
			dormancy
			emails
			employees
			inspectionDirectorate
			lastInspectionDate
			lastReportPublicationDate
			linkedinId
			location
			ladCode
			locationTypes
			mainPhoneNumber
			name
			numberOfBeds
			odsCode
			onspdCcgCode
			onspdCcgName
			onspdIcbCode
			onspdIcbName
			onspdLatitude
			onspdLongitude
			phoneNumber
			postalAddressLine1
			postalAddressTownCity
			postalCode
			regionCode
			registrationDate
			registrationStatus
			relationships
			servicesProviders
			sector
			serviceTypes
			source
			specialisms
			type
			updatedAt
			uprn
			website
		}
	}
`
