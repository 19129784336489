/**
 * @generated SignedSource<<2ca3fcb472f66e282ed085e7fcd7246f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesFilterOpts = {
  careHomes?: boolean | null | undefined;
  elderlyCare?: boolean | null | undefined;
  englandOnly?: boolean | null | undefined;
  registered?: boolean | null | undefined;
};
export type SitesQuery$variables = {
  filter?: SitesFilterOpts | null | undefined;
  limit: number;
  offset: number;
};
export type SitesQuery$data = {
  readonly sites: {
    readonly hasNextPage: boolean;
    readonly limit: number;
    readonly offset: number;
    readonly sites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SitesSiteFragment">;
    }>;
  };
};
export type SitesQuery = {
  response: SitesQuery$data;
  variables: SitesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "limit",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offset",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNextPage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SitesSiteFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SitesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ladCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mainPhoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBeds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onspdLatitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onspdLongitude",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c5a12cd59cda304614c2414425785a24",
    "id": null,
    "metadata": {},
    "name": "SitesQuery",
    "operationKind": "query",
    "text": "query SitesQuery(\n  $limit: Int!\n  $offset: Int!\n  $filter: SitesFilterOpts\n) {\n  sites(limit: $limit, offset: $offset, filter: $filter) {\n    limit\n    offset\n    hasNextPage\n    sites {\n      ...SitesSiteFragment\n    }\n  }\n}\n\nfragment SitesSiteFragment on Site {\n  id\n  ladCode\n  mainPhoneNumber\n  name\n  numberOfBeds\n  onspdLatitude\n  onspdLongitude\n}\n"
  }
};
})();

(node as any).hash = "2f37944d87ae6d485819aa8e4e1005ee";

export default node;
