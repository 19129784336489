import React from 'react'
import { useParams } from 'react-router-dom'

import { Page } from '../../layout/page/Page'
import { Container } from '../../shared/layout/Grid'
import { Mvu } from './Mvu'
import { Pdf } from './Pdf'

type RouteParams = Record<string, string | undefined>

const Report: React.FC = () => {
	const { id } = useParams<RouteParams>() // Get lad from the route

	if (!id) {
		return <Page title="Report">No ID found</Page>
	}

	return (
		<Page title={`Report - ${id}`}>
			<Container size="fluid">
				<Pdf>
					<Mvu id={id} />
				</Pdf>
			</Container>
		</Page>
	)
}

export { Report }
