import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { RadiusValues, SitesByCaQuery } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCa } from '../../graphql/queries/SitesByCa'
import { ElderlyBedsTimeSeriesChartSiteFragment$key } from './__generated__/ElderlyBedsTimeSeriesChartSiteFragment.graphql'
import { ElderlyBedsTimeSeriesChart } from './ElderlyBedsTimeSeriesChart'
import { ElderlyBedsTimeSeriesChartSiteFragment } from './ElderlyBedsTimeSeriesChartSiteFragment'
import { getElderlyBeds } from './helpers'

interface ElderlyBedsTimeSeriesChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyBedsTimeSeriesChart: React.FC<ElderlyBedsTimeSeriesChartProps> = ({ oaCode, radius }) => {
	const sitesData = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, {
		oaCode,
		radius,
	})

	const sites = useFragment<ElderlyBedsTimeSeriesChartSiteFragment$key>(
		ElderlyBedsTimeSeriesChartSiteFragment,
		sitesData.sitesByCa.sites,
	)

	const model = getElderlyBeds(sites)

	return <ElderlyBedsTimeSeriesChart model={model} />
}

export { CaElderlyBedsTimeSeriesChart }
