import graphql from 'babel-plugin-relay/macro'

export const AAAwardTypesByCa = graphql`
	query AAAwardTypesByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		aaAwardTypesByCa(oaCode: $oaCode, radius: $radius) {
			labels
			higherRate {
				code
				values
			}
			lowerRate {
				code
				values
			}
			total {
				code
				values
			}
		}
	}
`
