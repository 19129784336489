import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { CommunityCareServicesByCaQuery } from '../../graphql/queries/__generated__/CommunityCareServicesByCaQuery.graphql'
import { RadiusValues } from '../../graphql/queries/__generated__/CommunityCareServicesByCaQuery.graphql'
import { CommunityCareServicesByCaNode } from '../../graphql/queries/CommunityCareServicesByCa'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface StateFundedQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const StateFundedByCa: React.FC<StateFundedQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const { communityCareServicesByCa: ccs } = useLazyLoadQuery<CommunityCareServicesByCaQuery>(
		CommunityCareServicesByCaNode,
		{
			oaCode,
			radius,
		},
	)

	const ladValues = ccs.StateFunded[0].values
	const regionValues = ccs.StateFunded[ccs.StateFunded.length - 2].values
	const nationalValues = ccs.StateFunded[ccs.StateFunded.length - 1].values
	const comparisonValues = compareRegion ? regionValues : nationalValues
	const currentLadValue = ladValues[ladValues.length - 1]
	const comparisonValue = comparisonValues[comparisonValues.length - 1]

	return (
		<QuickFact
			heading="StateFunded"
			value={formatPercentage(currentLadValue)}
			comparison={formatPercentage(comparisonValue)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<StateFundedQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<StateFundedByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as StateFundedByCa }
