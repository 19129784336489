/**
 * @generated SignedSource<<7f545aa215e5938a5d3c7783e51917dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareSupplyRateChartSiteFragment$data = ReadonlyArray<{
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlyCareSupplyRateChartSiteFragment";
}>;
export type ElderlyCareSupplyRateChartSiteFragment$key = ReadonlyArray<{
  readonly " $data"?: ElderlyCareSupplyRateChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareSupplyRateChartSiteFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ElderlyCareSupplyRateChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "575dd1a5f29800d8d35afbb863cab7fb";

export default node;
