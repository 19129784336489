import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { HousePricesByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { HousePricesByCa } from '../../graphql/queries/HousePricesByCa'
import { formatCurrency } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface HousePriceAverageQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const HousePriceAverageByCa: React.FC<HousePriceAverageQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const data = useLazyLoadQuery<HousePricesByCaQuery>(HousePricesByCa, { oaCode, radius })
	const { labels, datasets } = data.housePricesByCa

	const index = labels.length - 1
	const housePriceCa = datasets[0].values[index]
	const housePriceRegion = datasets[datasets.length - 2].values[index]
	const housePriceNational = datasets[datasets.length - 1].values[index]
	const comparison = compareRegion ? housePriceRegion : housePriceNational

	return (
		<QuickFact
			heading="House Price Average"
			value={formatCurrency(housePriceCa, 0)}
			comparison={formatCurrency(comparison, 0)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<HousePriceAverageQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<HousePriceAverageByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as HousePriceAverageByCa }
