import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	AADisablingConditionsByCaQuery as DisResponse,
	RadiusValues,
} from '../../graphql/queries/__generated__/AADisablingConditionsByCaQuery.graphql'
import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByCaQuery } from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { AADisablingConditionsByCa } from '../../graphql/queries/AADisablingConditionsByCa'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { PopulationByCa } from '../../graphql/queries/PopulationByCa'
import { Dataset, Model } from '../../shared/charts/types'
import { DementiaPrevalenceRateChart } from './DementiaPrevalenceRateChart'

interface CaDementiaPrevalenceRateChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaDementiaPrevalenceRateChart: React.FC<CaDementiaPrevalenceRateChartProps> = ({ oaCode, radius }) => {
	// Fetch elderly population data
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: 2019,
		rangeSize: 100,
		minAge: 65,
		maxAge: 100,
		futureOffset: 5,
		includeIntermediates: true,
	})

	// Fetch dementia count data
	const { aaDisablingConditionsByCa: dis } = useLazyLoadQuery<DisResponse>(AADisablingConditionsByCa, {
		oaCode,
		radius,
	})

	const codes = pop.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	// Match the indexes of pop.years with dis.labels
	const yearIndexMap = new Map(dis.labels.map((label, index) => [label, index]))

	const datasets: Dataset[] = pop.geographies.map((geo, i) => ({
		id: geos.find((g) => g.code === geo.code)?.name || 'Catchment Area',
		values: geo.ageRanges[0].values.map((value, index) => {
			const year = pop.years[index]
			const disIndex = yearIndexMap.get(`${year}`)
			return disIndex !== undefined ? (dis.datasets[i].values[disIndex] / value) * 100 : 0
		}),
	}))

	const commonLabels = pop.years.filter((year) => dis.labels.includes(`${year}`))
	const labels = commonLabels.map((year) => year.toString())

	const model: Model = {
		labels,
		datasets,
	}

	return <DementiaPrevalenceRateChart model={model} />
}

export { CaDementiaPrevalenceRateChart }
