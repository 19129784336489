import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { ElderlyCareStatsByLadCodeQuery } from '../../graphql/queries/__generated__/ElderlyCareStatsByLadCodeQuery.graphql'
import { ElderlyCareStatsByLadCode } from '../../graphql/queries/ElderlyCareStatsByLad'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'
import { ElderlyCareSupplyRateQuickFactFragment$key } from './__generated__/ElderlyCareSupplyRateQuickFactFragment.graphql'
import { ElderlyCareSupplyRateQuickFactFragment } from './ElderlyCareSupplyRateQuickFactFragment'

interface ElderlyBedShortagesFactProps {
	ladCode: string
	compareRegion?: boolean
}

const ElderlyCareBedShortagesByLad: React.FC<ElderlyBedShortagesFactProps> = ({ ladCode, compareRegion }) => {
	const ecsData = useLazyLoadQuery<ElderlyCareStatsByLadCodeQuery>(ElderlyCareStatsByLadCode, {
		ladCode: ladCode,
	})

	const stats = useFragment<ElderlyCareSupplyRateQuickFactFragment$key>(
		ElderlyCareSupplyRateQuickFactFragment,
		ecsData.elderlyCareStatsByLadCode,
	)

	const bedShortagesLad = (stats.stats[0].numberOfBeds || 0) - (stats.stats[0].higherRateAllowance || 0)
	const bedShortageRegion = (stats.stats[1].numberOfBeds || 0) - (stats.stats[1].higherRateAllowance || 0)
	const bedShortageNational = (stats.stats[2].numberOfBeds || 0) - (stats.stats[2].higherRateAllowance || 0)

	const comparison = compareRegion ? bedShortageRegion : bedShortageNational

	return (
		<QuickFact
			heading="Bed Shortage"
			value={formatNumber(bedShortagesLad, 0, 0)}
			comparison={formatNumber(comparison, 0, 0)}
			isComparable={false}
			valueColoringNegativeVsPositive={true}
		/>
	)
}

const Suspended: React.FC<ElderlyBedShortagesFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareBedShortagesByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareBedShortagesByLad }
