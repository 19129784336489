/**
 * @generated SignedSource<<d88d9706068b1f6dc4310222b79632c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareSupplyRateQuickFactFragment$data = {
  readonly stats: ReadonlyArray<{
    readonly careSupplyRate: number | null | undefined;
    readonly code: string;
    readonly higherRateAllowance: number;
    readonly numberOfBeds: number;
    readonly type: GeographyTypes;
  }>;
  readonly " $fragmentType": "ElderlyCareSupplyRateQuickFactFragment";
};
export type ElderlyCareSupplyRateQuickFactFragment$key = {
  readonly " $data"?: ElderlyCareSupplyRateQuickFactFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareSupplyRateQuickFactFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyCareSupplyRateQuickFactFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElderlyCareData",
      "kind": "LinkedField",
      "name": "stats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "careSupplyRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "higherRateAllowance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElderlyCareStats",
  "abstractKey": null
};

(node as any).hash = "ce8bcd2a24359f7526f58c21a16ba627";

export default node;
