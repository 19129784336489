import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	RadiusValues,
	UnemploymentRatesByCaQuery,
} from '../../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { UnemploymentRatesByCa as Request } from '../../graphql/queries/UnemploymentRatesByCa'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface UnemploymentRatesQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const UnemploymentRatesByCa: React.FC<UnemploymentRatesQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const { unemploymentRatesByCa: uer } = useLazyLoadQuery<UnemploymentRatesByCaQuery>(Request, {
		oaCode,
		radius,
	})

	const Ca = uer.datasets[0].values[uer.datasets[0].values.length - 1]
	const region = uer.datasets[uer.datasets.length - 2].values[uer.datasets[0].values.length - 1]
	const national = uer.datasets[uer.datasets.length - 1].values[uer.datasets[0].values.length - 1]

	const comparison = compareRegion ? region : national

	return (
		<QuickFact
			heading="Unemployment rates"
			value={formatPercentage(Ca)}
			comparison={formatPercentage(comparison)}
			isComparable={true}
			reverseComparison={true}
		/>
	)
}

const Suspended: React.FC<UnemploymentRatesQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<UnemploymentRatesByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as UnemploymentRatesByCa }
