import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { AADisablingConditionsByLadCodeQuery } from '../../graphql/queries/__generated__/AADisablingConditionsByLadCodeQuery.graphql'
import { AADisablingConditionsByLadCode } from '../../graphql/queries/AADisablingConditionsByLad'
import { Model } from '../../shared/charts/types'
import { ElderlyWithSevereDementiaChart } from './ElderlyWithSevereDementiaChart'

interface LadElderlyWithSevereDementiaChartProps {
	ladCode: string
}

const LadElderlyWithSevereDementiaChart: React.FC<LadElderlyWithSevereDementiaChartProps> = ({ ladCode }) => {
	const { aaDisablingConditionsByLadCode: dis } = useLazyLoadQuery<AADisablingConditionsByLadCodeQuery>(
		AADisablingConditionsByLadCode,
		{
			ladCode,
		},
	)

	const datasets = dis.datasets.map((dataset) => ({
		id: 'Dementia',
		values: dataset.values.map((value) => value),
	}))

	const ladDataset = [datasets[0]]

	const model: Model = {
		labels: dis.labels.map((year) => year.toString()),
		datasets: ladDataset,
	}

	return <ElderlyWithSevereDementiaChart model={model} />
}

export { LadElderlyWithSevereDementiaChart }
