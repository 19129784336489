import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	GeographiesByCodesQuery,
	GeographiesByCodesQuery$data,
} from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCodeQuery$data } from '../../../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCodeQuery } from '../../../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { HousePricesByCaQuery$data } from '../../../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { HousePricesByLadCodeQuery$data } from '../../../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { HousePricesByLadCodeQuery } from '../../../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { TenureTypesByLadCodeQuery } from '../../../../graphql/queries/__generated__/TenureTypesByLadCodeQuery.graphql'
import { GeographiesByCodes as GeographiesByCodesRequest } from '../../../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByLadCode as GDHIRequest } from '../../../../graphql/queries/GrossDisposableHouseholdIncomesByLadCode'
import { HousePricesByLadCode } from '../../../../graphql/queries/HousePricesByLad'
import { TenureTypesByLadCode } from '../../../../graphql/queries/TenureTypesByLad'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { TextPlaceholder } from '../../../../shared/placeholder/Placeholder'

interface AIHousingSummaryProps {
	ladCode: string
}

type HP = HousePricesByCaQuery$data['housePricesByCa'] | HousePricesByLadCodeQuery$data['housePricesByLadCode']
type GDHI = GrossDisposableHouseholdIncomesByLadCodeQuery$data['grossDisposableHouseholdIncomesByLadCode']
type GEO = GeographiesByCodesQuery$data['geographiesByCodes']

const AIHousingSummary: React.FC<AIHousingSummaryProps> = ({ ladCode }) => {
	const housePrices = useLazyLoadQuery<HousePricesByLadCodeQuery>(HousePricesByLadCode, {
		ladCode: ladCode,
	})

	const { housePricesByLadCode: hp } = useLazyLoadQuery<HousePricesByLadCodeQuery>(HousePricesByLadCode, { ladCode })

	const { grossDisposableHouseholdIncomesByLadCode: gdhi } =
		useLazyLoadQuery<GrossDisposableHouseholdIncomesByLadCodeQuery>(GDHIRequest, {
			ladCode,
		})

	const codes = hp.datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodesRequest, {
		codes,
	})

	const yearsToBuy = calculateYearsToBuy(hp, gdhi, geos)

	const tenureTypes = useLazyLoadQuery<TenureTypesByLadCodeQuery>(TenureTypesByLadCode, { ladCode })

	const dataToAnalyse = {
		HousePrices: housePrices,
		YearsToBuy: yearsToBuy,
		TenureTypes: tenureTypes,
		Geographies: geos,
	}

	const prompt = `
You are a data analyst specializing in real estate markets. Analyze the housing situation and real estate market of the catchment area, comparing it to its Region and England, based solely on the data provided below.
Data:
1. Geographies: ${JSON.stringify(dataToAnalyse.Geographies)}
2. Average House Price Data Time Series:: ${JSON.stringify(dataToAnalyse.HousePrices)}
3. Housing Affordability Time Series Data (Lower number of years indicates better affordability.): ${JSON.stringify(dataToAnalyse.YearsToBuy)}
4. Tenure Situation: Percentage share (% as whole numbers) of households that own ("Owned"), rent privately ("Private rented"), or live in social housing ("Social rented"): ${JSON.stringify(dataToAnalyse.TenureTypes)}

Instructions:
Structure Your Response Without Labels:
Start directly with a concise headline (maximum 30 characters) summarizing the main finding. Do not include any labels, introductory words or quotes.
Follow with a paragraph of 90-150 characters that accurately reflects your analysis. Do not include any labels or introductory words.
Focus on Accurate Data Analysis:
Base all interpretations and conclusions directly on the data provided.
Double-check numerical values when making comparisons.
Remember that a lower number of years in affordability data indicates better affordability.
Do not make assumptions beyond the given data.
  `
	const iteration = 53

	const key = `housing-summary-${ladCode}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIHousingSummary: React.FC<AIHousingSummaryProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<AIHousingSummary ladCode={ladCode} />
		</React.Suspense>
	)
}

const calculateYearsToBuy = (hp: HP, gdhi: GDHI, geos: GEO) => {
	// Find common years
	const commonYears = hp.labels.filter((year) => gdhi.labels.includes(year))

	// Map indexes for the common years
	const hpYearIndexes = commonYears.map((year) => hp.labels.indexOf(year))
	const gdhiYearIndexes = commonYears.map((year) => gdhi.labels.indexOf(year))

	// Initialize datasets as a map for faster lookups
	const datasetsMap = new Map()

	hp.datasets.forEach((hpData) => {
		const gdhiData = gdhi.datasets.find((g) => g.code === hpData.code)

		if (!gdhiData) return

		commonYears.forEach((_, index) => {
			const hpValue = hpData.values[hpYearIndexes[index]]
			const gdhiValue = gdhiData.values[gdhiYearIndexes[index]] || 1

			const yearsToBuy = parseFloat((hpValue / gdhiValue).toFixed(1))

			if (!datasetsMap.has(hpData.code)) {
				datasetsMap.set(hpData.code, { code: hpData.code, values: [] })
			}

			datasetsMap.get(hpData.code).values.push(yearsToBuy)
		})
	})

	return {
		labels: commonYears,
		datasets: Array.from(datasetsMap.values()).map((d: any) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}
}

export { SuspendedAIHousingSummary as AIHousingSummary }
