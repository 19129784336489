import React, { useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery } from '../../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { LocalAuthorityByLadCode } from '../../../../graphql/queries/LocalAuthorityByLad'
import { CareFeeByLad } from '../../../../quickFacts/careFees/CareFeesByLad'
import { DementiaPrevalenceByLad } from '../../../../quickFacts/dementiaPrevalence/dementiaPrevalenceByLad'
import { ElderlyCareBedShortagesByLad } from '../../../../quickFacts/elderlyCareSupply/ElderlyCareBedShortagesByLad'
import { ElderlyCareSupplyRateByLad } from '../../../../quickFacts/elderlyCareSupply/ElderlyCareSupplyRateByLad'
import { ElderlyPopulationShareByLad } from '../../../../quickFacts/elderlyPopulationShare/ElderlyPopulationShareByLad'
import { PopulationByLad } from '../../../../quickFacts/population/PopulationByLad'
import { StateFundedByLad } from '../../../../quickFacts/stateFunded/StateFundedByLad'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { ToggleButtonGroup } from '../../../../shared/ToggleButtonGroup'

interface LadQuickFactsProps {
	ladCode: string
}

const ElderlyCareMarketQuickFactsByLad: React.FC<LadQuickFactsProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LocalAuthorityByLadCodeQuery>(LocalAuthorityByLadCode, {
		ladCode,
	})

	const regionName = la.regionName
	const countryName = la.countryName
	const [compareRegion, setCompareRegion] = useState(true)

	const toggleOptions = [
		{ label: regionName, value: 'region' },
		{ label: countryName, value: 'national' },
	]

	const handleToggleChange = (value: string) => {
		setCompareRegion(value === 'region')
	}
	return (
		<>
			<Container removePadding>
				<Row>
					<Col count={12}>
						<Panel padding={16}>
							<ToggleButtonGroup
								options={toggleOptions}
								activeValue={compareRegion ? 'region' : 'national'}
								onChange={handleToggleChange}
							/>
						</Panel>
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<PopulationByLad ladCode={ladCode} compareRegion={compareRegion} />
					</Col>
					<Col count={6}>
						<StateFundedByLad ladCode={ladCode} />
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<ElderlyPopulationShareByLad age={65} ladCode={ladCode} compareRegion={compareRegion} />
					</Col>
					<Col count={6}>
						<ElderlyPopulationShareByLad age={85} ladCode={ladCode} compareRegion={compareRegion} />
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<CareFeeByLad ladCode={ladCode} />
					</Col>
					<Col count={6}>
						<DementiaPrevalenceByLad ladCode={ladCode} compareRegion={compareRegion} />
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<ElderlyCareBedShortagesByLad ladCode={ladCode} compareRegion={compareRegion} />
					</Col>
					<Col count={6}>
						<ElderlyCareSupplyRateByLad ladCode={ladCode} compareRegion={compareRegion} />
					</Col>
				</Row>
			</Container>
		</>
	)
}

const Suspended: React.FC<LadQuickFactsProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<ElderlyCareMarketQuickFactsByLad ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareMarketQuickFactsByLad }
