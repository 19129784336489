import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GrossDisposableHouseholdIncomesByLadCodeQuery } from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { HousePricesByLadCodeQuery } from '../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCode } from '../../graphql/queries/GrossDisposableHouseholdIncomesByLadCode'
import { HousePricesByLadCode } from '../../graphql/queries/HousePricesByLad'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface YearsToBuyQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const YearsToBuyByLad: React.FC<YearsToBuyQuickFactProps> = ({ ladCode, compareRegion }) => {
	const housePriceData = useLazyLoadQuery<HousePricesByLadCodeQuery>(HousePricesByLadCode, { ladCode })
	const { labels: hpLabels, datasets: hpDatasets } = housePriceData.housePricesByLadCode

	const hpIndex = hpLabels.length - 1
	const housePriceLad = hpDatasets[0].values[hpIndex]
	const housePriceRegion = hpDatasets[hpDatasets.length - 2].values[hpIndex]
	const housePriceNational = hpDatasets[hpDatasets.length - 1].values[hpIndex]

	const gdhiData = useLazyLoadQuery<GrossDisposableHouseholdIncomesByLadCodeQuery>(
		GrossDisposableHouseholdIncomesByLadCode,
		{ ladCode },
	)
	const { labels: gdhiLabels, datasets: gdhiDatasets } = gdhiData.grossDisposableHouseholdIncomesByLadCode

	const gdhiIndex = gdhiLabels.length - 1
	const gdhiLad = gdhiDatasets[0].values[gdhiIndex]
	const gdhiRegion = gdhiDatasets[gdhiDatasets.length - 2].values[gdhiIndex]
	const gdhiNational = gdhiDatasets[gdhiDatasets.length - 1].values[gdhiIndex]

	const yearsToBuyLad = housePriceLad / gdhiLad
	const yearsToBuyRegion = housePriceRegion / gdhiRegion
	const yearsToBuyNational = housePriceNational / gdhiNational

	const comparison = compareRegion ? yearsToBuyRegion : yearsToBuyNational

	return (
		<QuickFact
			heading="Years-to-Buy"
			value={formatNumber(yearsToBuyLad, 0, 1)}
			comparison={formatNumber(comparison, 0, 1)}
			isComparable={true}
			reverseComparison={true}
		/>
	)
}

const Suspended: React.FC<YearsToBuyQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<YearsToBuyByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as YearsToBuyByLad }
