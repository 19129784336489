import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	GrossDisposableHouseholdIncomesByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { GrossDisposableHouseholdIncomesByCa } from '../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { formatCurrency } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface GDHIQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const GDHIByCa: React.FC<GDHIQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const data = useLazyLoadQuery<GrossDisposableHouseholdIncomesByCaQuery>(GrossDisposableHouseholdIncomesByCa, {
		oaCode,
		radius,
	})
	const { labels, datasets } = data.grossDisposableHouseholdIncomesByCa

	const index = labels.length - 1
	const gdhiCa = datasets[0].values[index]
	const gdhiRegion = datasets[datasets.length - 2].values[index]
	const gdhiNational = datasets[datasets.length - 1].values[index]
	const comparison = compareRegion ? gdhiRegion : gdhiNational

	return (
		<QuickFact
			heading="Gross Disposable Household Income"
			value={formatCurrency(gdhiCa, 0)}
			comparison={formatCurrency(comparison, 0)}
			isComparable={true}
			comparisonRegion={formatCurrency(gdhiRegion, 0)}
		/>
	)
}

const Suspended: React.FC<GDHIQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<GDHIByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as GDHIByCa }
