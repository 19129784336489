/**
 * @generated SignedSource<<7bf414228c109982dafd357e67ceb135>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RadiusValues = "FIVE" | "ONE" | "TEN" | "THREE" | "%future added value";
export type ElderlyCareStatsByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type ElderlyCareStatsByCaQuery$data = {
  readonly elderlyCareStatsByCa: {
    readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareSupplyRateQuickFactByCaFragment" | "ElderlyCareSupplyRateQuickFactFragment">;
  };
};
export type ElderlyCareStatsByCaQuery = {
  response: ElderlyCareStatsByCaQuery$data;
  variables: ElderlyCareStatsByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "oaCode",
    "variableName": "oaCode"
  },
  {
    "kind": "Variable",
    "name": "radius",
    "variableName": "radius"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ElderlyCareStatsByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ElderlyCareStats",
        "kind": "LinkedField",
        "name": "elderlyCareStatsByCa",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareSupplyRateQuickFactFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareSupplyRateQuickFactByCaFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ElderlyCareStatsByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ElderlyCareStats",
        "kind": "LinkedField",
        "name": "elderlyCareStatsByCa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElderlyCareData",
            "kind": "LinkedField",
            "name": "stats",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "careSupplyRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBeds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "higherRateAllowance",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49237ecb9744e8492641d988fd6c57ee",
    "id": null,
    "metadata": {},
    "name": "ElderlyCareStatsByCaQuery",
    "operationKind": "query",
    "text": "query ElderlyCareStatsByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  elderlyCareStatsByCa(oaCode: $oaCode, radius: $radius) {\n    ...ElderlyCareSupplyRateQuickFactFragment\n    ...ElderlyCareSupplyRateQuickFactByCaFragment\n  }\n}\n\nfragment ElderlyCareSupplyRateQuickFactByCaFragment on ElderlyCareStats {\n  stats {\n    code\n    type\n    careSupplyRate\n    numberOfBeds\n    higherRateAllowance\n  }\n}\n\nfragment ElderlyCareSupplyRateQuickFactFragment on ElderlyCareStats {\n  stats {\n    code\n    type\n    careSupplyRate\n    numberOfBeds\n    higherRateAllowance\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1089a3a84f0997a6bf32f214d0bba70";

export default node;
