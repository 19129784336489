import graphql from 'babel-plugin-relay/macro'

export const SitesByLadCode = graphql`
	query SitesByLadCodeQuery($ladCode: String!) {
		sitesByLadCode(ladCode: $ladCode) {
			sites {
				...CareHomesSitesByLadFragment
				...SitesTableFragment
				...ElderlyBedShortagesForecastChartSiteFragment
				...ElderlySitesChartSiteFragment
				...ElderlyCareSupplyRateChartSiteFragment
				...ElderlyBedsTimeSeriesChartSiteFragment
			}
		}
	}
`
