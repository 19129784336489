import graphql from 'babel-plugin-relay/macro'

export const OperatorsByCa = graphql`
	query OperatorsByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		operatorsByCa(oaCode: $oaCode, radius: $radius) {
			operators {
				...OperatorsTableFragment
			}
		}
	}
`
