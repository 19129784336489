import React, { useEffect, useState } from 'react'

interface PdfProps {
	children: React.ReactNode
	landscape?: boolean
}

const Pdf: React.FC<PdfProps> = ({ children, landscape = true }) => {
	const [scale, setScale] = useState(1)

	const width = landscape ? '297mm' : '210mm'
	const height = landscape ? '210mm' : '297mm'

	useEffect(() => {
		const resizeListener = () => {
			const canvasElement = document.getElementById('reportCanvas')
			if (canvasElement) {
				const canvasWidth = canvasElement.offsetWidth
				const canvasHeight = canvasElement.offsetHeight
				const scaleX = (window.innerWidth - 50) / canvasWidth
				const scaleY = (window.innerHeight - 150) / canvasHeight
				const scale = Math.min(scaleX, scaleY)
				setScale(scale)
			}
		}

		window.addEventListener('resize', resizeListener)
		resizeListener() // Initial calculation

		return () => {
			window.removeEventListener('resize', resizeListener)
		}
	}, [])

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '80vh',
				// border: '1px solid red',
			}}
		>
			<div
				id="reportCanvas"
				style={{
					width, // A4 width in mm
					height, // A4 height in mm

					backgroundColor: 'white',
					boxShadow: '0px 0px 25px rgba(80, 80, 80, 0.1)',
					position: 'absolute', // Absolute positioning to control placement
					top: '50%', // Position from the top
					left: '50%', // Position from the left
					transform: `scale(${scale}) translate(-50%, -50%)`, // Scale and center
					transformOrigin: 'top left', // Keeps the top-left corner fixed during scaling
					overflow: 'hidden', // Prevents overflow while scaling
				}}
			>
				{children}
			</div>
		</div>
	)
}

export { Pdf }
