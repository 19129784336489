import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { ProjectsByUserIdQuery } from '../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { ProjectsByUserId } from '../../graphql/queries/ProjectsByUserId'
import { Loading } from '../../shared/Loading'

interface MvuProps {
	id: string
}

const Mvu: React.FC<MvuProps> = ({ id }) => {
	const data = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { userId: '1' })
	const projects = data.projectsByUserId?.projects || []
	const project = projects.find((p) => p.id === id)

	return <div>{project?.client}</div>
}

const Suspended: React.FC<MvuProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Mvu {...props} />
		</React.Suspense>
	)
}

export { Suspended as Mvu }
