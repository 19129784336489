import React, { useState } from 'react'
import * as i from 'react-feather'
import { useMutation } from 'react-relay'

import { AddOperatorsToGroupMutation } from '../graphql/queries/AddOperatorsToGroup'
import { PrimaryButton, SecondaryButton, SecondaryOutlineButton } from '../shared/Button'
import { VerticalSpace } from '../shared/layout/Space'
import { Modal } from '../shared/modal/Modal'
import { Paragraph } from '../shared/Text'
import { SearchOperatorGroups } from './SearchOperatorGroups'

interface AddOperatorToGroupButtonProps {
	operatorId: string
}

const AddOperatorToGroupButton: React.FC<AddOperatorToGroupButtonProps> = ({ operatorId }) => {
	const [showModal, setShowModal] = useState(false)
	const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null) // State for the selected group
	const [commitMutation, isMutationInFlight] = useMutation(AddOperatorsToGroupMutation)

	const handleShow = () => setShowModal(true)
	const handleClose = () => setShowModal(false)

	const handleConfirm = () => {
		if (!selectedGroupId) {
			alert('Please select a group before proceeding.')
			return
		}

		commitMutation({
			variables: {
				id: selectedGroupId, // Use the selected group ID
				operatorIds: [operatorId],
			},
			onCompleted: (response) => {
				console.log('Mutation successful:', response)
				handleClose() // Close the modal on success
				window.location.reload()
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	return (
		<>
			<SecondaryOutlineButton onClick={handleShow}>
				<i.PlusCircle size={14} color="#666" />
				<Paragraph>Add to group</Paragraph>
			</SecondaryOutlineButton>

			<Modal
				isOpen={showModal}
				onClose={handleClose}
				title="Add Operator to Group"
				footer={
					<>
						<SecondaryButton onClick={handleClose} disabled={isMutationInFlight}>
							Cancel
						</SecondaryButton>
						<PrimaryButton
							onClick={handleConfirm}
							disabled={isMutationInFlight || !selectedGroupId} // Disable if no group is selected
						>
							<i.PlusCircle size={14} />
							&nbsp;Add to group
						</PrimaryButton>
					</>
				}
			>
				<div className="form-group">
					<label htmlFor="searchOperator">Search operator group</label>
					<VerticalSpace size="xs" />
					<SearchOperatorGroups onSelectedOperatorGroup={(groupId) => setSelectedGroupId(groupId)} />
				</div>
			</Modal>
		</>
	)
}

export { AddOperatorToGroupButton }
