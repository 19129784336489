import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LadElderlyBedsTimeSeriesChart } from '../../../../charts/ElderlyBedsTimeSeriesChart/LadElderlyBedsTimeSeriesChart'
import { LadElderlySitesChart } from '../../../../charts/ElderlySitesChart/LadElderlySitesChart'
import { LocalAuthorityByLadCodeQuery } from '../../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { SitesByLadCodeQuery } from '../../../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { LocalAuthorityByLadCode } from '../../../../graphql/queries/LocalAuthorityByLad'
import { SitesByLadCode } from '../../../../graphql/queries/SitesByLadCode'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { MapFeatures, Marker } from '../../../../shared/map/MapFeatures'
import { LaOperatorsTable } from '../../../../shared/operatorsTable/LaOperatorsTable'
import { Panel } from '../../../../shared/Panel'
import { LaSitesTable } from '../../../../shared/sitesTable/LaSitesTable'
import { Heading, Paragraph, SubtitleBox } from '../../../../shared/Text'
import { ToggleButtonGroup } from '../../../../shared/ToggleButtonGroup'
import { filterEldery } from '../../../../utils/sites'
import { CareHomesSitesByLadFragment$key } from './__generated__/CareHomesSitesByLadFragment.graphql'
import { CareHomesSitesByLadFragment } from './CareHomesSitesByLadFragment'

interface CareHomesProps {
	ladCode: string
}

const CareHomes: React.FC<CareHomesProps> = ({ ladCode }) => {
	const [view, setView] = useState('careHomes')

	const options = [
		{ label: 'Care Homes', value: 'careHomes' },
		{ label: 'Operators', value: 'operators' },
	]

	const handleViewChange = (value: string) => {
		setView(value)
	}

	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LocalAuthorityByLadCodeQuery>(LocalAuthorityByLadCode, {
		ladCode,
	})
	const { sitesByLadCode: si } = useLazyLoadQuery<SitesByLadCodeQuery>(SitesByLadCode, { ladCode })

	const allSites = useFragment<CareHomesSitesByLadFragment$key>(CareHomesSitesByLadFragment, si.sites)
	const sites = filterEldery(allSites)

	const locations: Marker[] = sites.map((siteData) => {
		return {
			location: JSON.parse(siteData.location!),
			name: siteData.name,
			id: siteData.id,
		}
	})

	const { geometry } = la

	const feature: Feature<Polygon | MultiPolygon> = {
		type: 'Feature',
		geometry: JSON.parse(geometry),
		properties: {},
	}

	const featureCollection = turf.featureCollection([feature])
	const featureCollections = [featureCollection]

	const title = `${sites.length} Care Homes in ${la.name}`

	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox text-transform={'none'}>Care Providers</SubtitleBox>
						<Heading level={2}>{title}</Heading>
						<VerticalSpace size="xs" />
						<Paragraph>
							Comprehensive data on care homes, including detailed information on care providers, the number of sites,
							and bed availability over time. Users can view and analyze the geographical distribution of care homes and
							operators within a specified radius, utilizing interactive charts and tables to gain insights into the
							care home landscape.
						</Paragraph>
					</Panel>
					<VerticalSpace size="sm" />
					<Panel padding={8} height={'auto'}>
						<MapFeatures features={featureCollections} locations={locations} />
					</Panel>
				</Col>
				<Col count={8}>
					<Panel padding={16} height={'auto'}>
						<ToggleButtonGroup options={options} activeValue={view} onChange={handleViewChange} />
					</Panel>
					<VerticalSpace size="sm" />
					{view === 'careHomes' ? (
						<>
							<Row>
								<Col count={6}>
									<Panel>
										<LadElderlySitesChart ladCode={ladCode} />
									</Panel>
								</Col>
								<Col count={6}>
									<Panel height={'auto'}>
										<LadElderlyBedsTimeSeriesChart ladCode={ladCode} />
									</Panel>
								</Col>
							</Row>
							<VerticalSpace size="sm" />
							<Row>
								<Col count={12}>
									<Panel>
										<LaSitesTable ladCode={ladCode} />
									</Panel>
								</Col>
							</Row>
						</>
					) : (
						<Panel>
							<LaOperatorsTable ladCode={ladCode} />
						</Panel>
					)}
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<CareHomesProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareHomes ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as CareHomes }
