const debounce = (func: (...args: any[]) => void, delay: number) => {
	let timeoutId: ReturnType<typeof setTimeout>
	const debouncedFunc = (...args: any[]) => {
		clearTimeout(timeoutId)
		timeoutId = setTimeout(() => func(...args), delay)
	}

	debouncedFunc.cancel = () => {
		clearTimeout(timeoutId)
	}

	return debouncedFunc
}

export { debounce }
