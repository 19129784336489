import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { AADisablingConditionsByLadCodeQuery } from '../../graphql/queries/__generated__/AADisablingConditionsByLadCodeQuery.graphql'
import { PopulationByLadCodeQuery } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { AADisablingConditionsByLadCode } from '../../graphql/queries/AADisablingConditionsByLad'
import { PopulationByLadCode } from '../../graphql/queries/PopulationByLad'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface DementiaPrevalenceQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const DementiaPrevalenceByLad: React.FC<DementiaPrevalenceQuickFactProps> = ({ ladCode, compareRegion }) => {
	const { populationByLadCode: pop } = useLazyLoadQuery<PopulationByLadCodeQuery>(PopulationByLadCode, {
		ladCode,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 65,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const dementiaData = useLazyLoadQuery<AADisablingConditionsByLadCodeQuery>(AADisablingConditionsByLadCode, {
		ladCode,
	})
	const { labels: dementiaLabels, datasets: dementiaDatasets } = dementiaData.aaDisablingConditionsByLadCode

	const dementiaIndex = dementiaLabels.length - 1
	const dementiaLad = dementiaDatasets[0].values[dementiaIndex]
	const dementiaRegion = dementiaDatasets[1].values[dementiaIndex]
	const dementiaNational = dementiaDatasets[2].values[dementiaIndex]

	const popLad = pop.geographies[0].ageRanges[0].values[0]
	const popRegion = pop.geographies[1].ageRanges[0].values[0]
	const popNational = pop.geographies[2].ageRanges[0].values[0]

	const dementiaPrevalenceLad = (dementiaLad / popLad) * 100
	const dementiaPrevalenceRegion = (dementiaRegion / popRegion) * 100
	const dementiaPrevalenceNational = (dementiaNational / popNational) * 100
	const comparison = compareRegion ? dementiaPrevalenceRegion : dementiaPrevalenceNational

	return (
		<QuickFact
			heading="Dementia Prevalence"
			value={formatPercentage(dementiaPrevalenceLad, 0, 1)}
			comparison={formatPercentage(comparison, 0, 1)}
			isComparable={true}
			reverseComparison={true}
		/>
	)
}

const Suspended: React.FC<DementiaPrevalenceQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<DementiaPrevalenceByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as DementiaPrevalenceByLad }
