import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { UnemploymentRatesByLadCodeQuery } from '../../graphql/queries/__generated__/UnemploymentRatesByLadCodeQuery.graphql'
import { UnemploymentRatesByLadCode as UerResponseType } from '../../graphql/queries/UnemploymentRatesByLad'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface UnemploymentRatesQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const UnemploymentRatesByLad: React.FC<UnemploymentRatesQuickFactProps> = ({ ladCode, compareRegion }) => {
	const { unemploymentRatesByLadCode: uer } = useLazyLoadQuery<UnemploymentRatesByLadCodeQuery>(UerResponseType, {
		ladCode,
	})

	const lad = uer.datasets[0].values[uer.datasets[0].values.length - 1]
	const region = uer.datasets[uer.datasets.length - 2].values[uer.datasets[0].values.length - 1]
	const national = uer.datasets[uer.datasets.length - 1].values[uer.datasets[0].values.length - 1]
	const comparison = compareRegion ? region : national

	return (
		<QuickFact
			heading="Unemployment Rate"
			value={formatPercentage(lad)}
			comparison={formatPercentage(comparison)}
			isComparable={true}
			reverseComparison={true}
		/>
	)
}

const Suspended: React.FC<UnemploymentRatesQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<UnemploymentRatesByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as UnemploymentRatesByLad }
