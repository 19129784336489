import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface ElderlyPopulationIn10YearsChartProps {
	model: Model
}

const ElderlyPopulationIn10YearsChart: React.FC<ElderlyPopulationIn10YearsChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Growth of Elderly Population</Subtitle>
			<Paragraph>10 year forecast | Based on Census 2021 Data</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={false} model={model} showLabels={true} alpha={1} aspectRatio={2} />
		</>
	)
}

export { ElderlyPopulationIn10YearsChart }
