import graphql from 'babel-plugin-relay/macro'

export const CommunityCareServicesByLadCodeNode = graphql`
	query CommunityCareServicesByLadCodeQuery($ladCode: String!) {
		communityCareServicesByLadCode(ladCode: $ladCode) {
			labels
			StateFunded {
				code
				values
			}
			SelfFunded {
				code
				values
			}
		}
	}
`
