import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	GrossDisposableHouseholdIncomesByCaQuery,
	RadiusValues,
} from '../../../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { IndustryDistributionsByCaQuery } from '../../../../graphql/queries/__generated__/IndustryDistributionsByCaQuery.graphql'
import { UnemploymentRatesByCaQuery } from '../../../../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { GeographiesByCodes } from '../../../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByCa } from '../../../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { IndustryDistributionsByCa } from '../../../../graphql/queries/IndustryDistributionsByCa'
import { UnemploymentRatesByCa } from '../../../../graphql/queries/UnemploymentRatesByCa'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { Model } from '../../../../shared/charts/types'
import { TextPlaceholder } from '../../../../shared/placeholder/Placeholder'
import { transformToMarkdownTable } from '../../../../utils/transformToMarkdownTable'

interface AIEconomicSummaryProps {
	oaCode: string
	radius: RadiusValues
}

const AIEconomicSummary: React.FC<AIEconomicSummaryProps> = ({ oaCode, radius }) => {
	const { unemploymentRatesByCa: { labels: unemploymentLabels = [], datasets: unemploymentDatasets = [] } = {} } =
		useLazyLoadQuery<UnemploymentRatesByCaQuery>(UnemploymentRatesByCa, { oaCode, radius })

	const { grossDisposableHouseholdIncomesByCa: { labels: gdhiLabels = [], datasets: gdhiDatasets = [] } = {} } =
		useLazyLoadQuery<GrossDisposableHouseholdIncomesByCaQuery>(GrossDisposableHouseholdIncomesByCa, {
			oaCode,
			radius,
		})

	const { industryDistributionsByCa: { labels: industryLabels = [], datasets: industryDatasets = [] } = {} } =
		useLazyLoadQuery<IndustryDistributionsByCaQuery>(IndustryDistributionsByCa, {
			oaCode,
			radius,
		})

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes: unemploymentDatasets.map((d) => d.code),
	})

	const industryDatasetsProcessed = industryDatasets.map((d) => {
		return d.values.map((v, i) => ({
			value: v,
			industry: industryLabels[i],
		}))
	})

	const sortedCa = [...industryDatasetsProcessed[0]].sort((a, b) => b.value - a.value)
	const sortedIndustries = sortedCa.map((item) => item.industry)
	const sortedDatasetValues = industryDatasetsProcessed.map((dataset) => {
		const sorted = sortedIndustries.map((industry) => {
			return dataset.find((item) => item.industry === industry)
		})
		return sorted.map((item) => item?.value || 0)
	})

	const industryModel: Model = {
		labels: sortedIndustries,
		datasets: industryDatasets.map((d, i) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: sortedDatasetValues[i],
		})),
	}

	const unemploymentModel: Model = {
		labels: [...unemploymentLabels],
		datasets: unemploymentDatasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	const gdhiModel: Model = {
		labels: [...gdhiLabels],
		datasets: gdhiDatasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	const catchmentGeographies = geos.map((geo) => ({ code: geo.code, name: geo.name }))
	const gdhiFormatted = transformToMarkdownTable(gdhiModel)
	const unemploymentFormatted = transformToMarkdownTable(unemploymentModel)
	const industryFormatted = transformToMarkdownTable(industryModel)

	const prompt = `
You are a data analyst, analyze the economic situation of the catchment area, comparing it to its Region and England based solely on the data provided below.

Data:
1. Catchment area: ${JSON.stringify(catchmentGeographies)}
2. Gross Disposable Household Income:
where 
| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(gdhiFormatted)}
3. Unemployment Rate:
| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(unemploymentFormatted)}
4. Industry Sizes by share of workforce:
| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(industryFormatted)}

**Instructions:**

Structure Your Response Without Labels:
- Start directly with a concise headline (maximum 30 characters) summarizing the main finding. Do not include any label words here (e.g., do not start the sentence with something like "Headline:" or use quotes).
- Follow with a paragraph of 90-200 characters that accurately reflects your analysis. Do not include any labels or introductory words.
`

	const iteration = 23
	const key = `economic-summary-${oaCode}-${radius}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIEconomicSummary: React.FC<AIEconomicSummaryProps> = (props) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<AIEconomicSummary {...props} />
		</React.Suspense>
	)
}

export { SuspendedAIEconomicSummary as AIEconomicSummary }
