import graphql from 'babel-plugin-relay/macro'

export const OperatorGroupById = graphql`
	query OperatorGroupByIdQuery($id: ID!) {
		operatorGroupById(id: $id) {
			id
			name
			operators {
				id
				name
			}
		}
	}
`
