import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareStatsByLadCode = graphql`
	query ElderlyCareStatsByLadCodeQuery($ladCode: String!) {
		elderlyCareStatsByLadCode(ladCode: $ladCode) {
			...ElderlyCareSupplyRateQuickFactFragment
			...ElderlyCareSupplyRateQuickFactFragment
		}
	}
`
