import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LadElderlyBedShortagesForecastChart } from '../../../../charts/ElderlyBedShortagesForecastChart/LadElderlyBedShortagesForecastChart'
import { LadElderlyCareSupplyRateChart } from '../../../../charts/ElderlyCareSupplyRateChart/LadElderlyCareSupplyRateChart'
import { LocalAuthorityByLadCodeQuery } from '../../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { LocalAuthorityByLadCode } from '../../../../graphql/queries/LocalAuthorityByLad'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { PageTitle, ParagraphBold, SubtitleBox } from '../../../../shared/Text'
import { ElderlyCareMarketQuickFactsByLad } from './ElderlyCareMarketQuickFactsByLad'

interface ElderlyCareMarketProps {
	ladCode: string
}

const ElderlyCareMarket: React.FC<ElderlyCareMarketProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LocalAuthorityByLadCodeQuery>(LocalAuthorityByLadCode, {
		ladCode,
	})
	const { name } = la
	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox>Elderly Care Market</SubtitleBox>
						<PageTitle> {name} </PageTitle>
						<ParagraphBold>
							Comprehensive data and insights about the elderly care market within the specified local authority
							district. It includes key statistics, forecasts, and comparisons to help understand the current and future
							state of elderly care services in the area.
						</ParagraphBold>
					</Panel>
					<VerticalSpace size="sm" />
					<ElderlyCareMarketQuickFactsByLad ladCode={ladCode} />
				</Col>
				<Col count={4}>
					<Panel>
						<VerticalSpace size="sm" />
						<LadElderlyCareSupplyRateChart ladCode={ladCode} />
						<VerticalSpace size="sm" />
					</Panel>

					<VerticalSpace size="md" />
				</Col>
				<Col count={4}>
					<Panel>
						<VerticalSpace size="sm" />
						<LadElderlyBedShortagesForecastChart ladCode={ladCode} />
						<VerticalSpace size="sm" />
					</Panel>
					<VerticalSpace size="md" />
				</Col>
			</Row>
			<VerticalSpace size="md" />
		</Container>
	)
}

const Suspended: React.FC<ElderlyCareMarketProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<ElderlyCareMarket ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareMarket }
