import { siteRatingPoints } from '../Constants'
import { CAGRDisplay } from './CAGR'
import { formatNumber } from './formatNumber'
import { getDateOffsetByYears } from './getDateOffsetByYears'

export interface OperatorData {
	id: string
	name: string
	operatorGroupName?: string | null
	sites?: readonly SiteData[] | null
}

export interface SiteData {
	id: string
	ladCode?: string | null
	region?: string | null
	specialisms?: { name: string }[] | null
	numberOfBeds?: number | null
	registrationDate?: string | null
	servicesProviders?: string | null
	currentRatings?: {
		overall?: {
			rating?: string | null
		} | null
	} | null
}

export interface OperatorRowData {
	avgNewBedsPerYear: string
	avgNewLasPerYear: string
	avgNewSitesPerYear: string
	bedsCAGRDisplay: string
	bedsCountLA: number
	careHomesLA: number
	currentBedsCountTotal: number
	currentRatingsMean: string | number
	currentSitesCount: number
	facilitiesCountLA: number
	id: string
	lasActiveListCount: number
	name: string
	operatorGroupName?: string | null
	regionsActiveList: string
	sitesCAGRDisplay: string
	specialismsUniqueAggregatedList: string
}

export function totalBedCountForSites(sites: readonly SiteData[]): number {
	return sites.reduce((total, site) => total + (site.numberOfBeds ?? 0), 0)
}

export function calculateOperatorMetrics(op: OperatorData, code?: string, timePeriodYears = -3): OperatorRowData {
	const initialTimestamp = getDateOffsetByYears(timePeriodYears)
	const timePeriodYearsAbs = Math.abs(timePeriodYears)
	const { operatorGroupName, sites: opSites, name, id } = op
	const sites = opSites ?? []

	// Specialisms
	const specialismsUniqueAggregatedList = Array.from(
		new Set(sites.flatMap((site) => site.specialisms?.map((s) => s.name) ?? [])),
	).join(', ')

	// Current counts
	const currentSitesCount = sites.length
	const currentBedsCountTotal = totalBedCountForSites(sites)

	// LA-specific data if 'code' is provided
	let bedsCountLA = 0
	let facilitiesCountLA = 0
	let careHomesLA = 0
	if (code) {
		const laSites = sites.filter((site) => site.ladCode === code)
		bedsCountLA = totalBedCountForSites(laSites)
		facilitiesCountLA = laSites.length
		careHomesLA = laSites.filter((site) => site.servicesProviders?.includes('Care Homes') ?? false).length
	}

	const lasActiveListCount = new Set(sites.map((site) => site.ladCode)).size
	const regionsActiveList = Array.from(new Set(sites.map((site) => site.region ?? '')))
		.filter((region) => region)
		.join(', ')

	// Past counts
	const sitesExistingAtInitial = sites.filter(
		(site) => site.registrationDate && new Date(site.registrationDate) < initialTimestamp,
	)
	const sitesCountInitial = sitesExistingAtInitial.length
	const bedsCountInitial = totalBedCountForSites(sitesExistingAtInitial)

	// Avg. new sites and beds per year
	const newSitesLastPeriod = sites.filter(
		(site) => site.registrationDate && new Date(site.registrationDate) >= initialTimestamp,
	)
	const newSitesCountLastPeriod = newSitesLastPeriod.length

	const avgNewSitesPerYear = formatNumber(newSitesCountLastPeriod / timePeriodYearsAbs, 0, 1)

	const newBedsLastPeriod = totalBedCountForSites(newSitesLastPeriod)
	const avgNewBedsPerYear = formatNumber(newBedsLastPeriod / timePeriodYearsAbs, 0, 0)

	const newLasLastPeriodList = new Set(newSitesLastPeriod.map((site) => site.ladCode))
	const avgNewLasPerYear = formatNumber(newLasLastPeriodList.size / timePeriodYearsAbs, 0, 1)

	// CAGR
	const sitesCAGRDisplay = CAGRDisplay(sitesCountInitial, currentSitesCount)
	const bedsCAGRDisplay = CAGRDisplay(bedsCountInitial, currentBedsCountTotal)

	// Ratings
	const ratings = sites
		.map((site) => {
			const ratingKey = site.currentRatings?.overall?.rating as keyof typeof siteRatingPoints
			return ratingKey ? siteRatingPoints[ratingKey] : null
		})
		.filter((rating) => rating !== null) as number[]

	const currentRatingsMean = ratings.length
		? formatNumber(ratings.reduce((a, b) => a + b, 0) / ratings.length, 1, 1)
		: 'NA'

	const result: OperatorRowData = {
		id,
		name,
		operatorGroupName,
		specialismsUniqueAggregatedList,
		currentBedsCountTotal,
		currentSitesCount,
		lasActiveListCount,
		regionsActiveList,
		currentRatingsMean,
		avgNewSitesPerYear,
		sitesCAGRDisplay,
		avgNewLasPerYear,
		avgNewBedsPerYear,
		bedsCAGRDisplay,
		careHomesLA,
		bedsCountLA,
		facilitiesCountLA,
	}

	return result
}
