import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	AAUniversalCreditsByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/AAUniversalCreditsByCaQuery.graphql'
import { AAUniversalCreditsByCa } from '../../graphql/queries/AAUniversalCreditsByCa'
import { Model } from '../../shared/charts/types'
import { HouseholdsWithUniversalCreditChart } from './HouseholdsWithUniversalCreditChart'

interface CaHouseholdsWithUniversalCreditChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaHouseholdsWithUniversalCreditChart: React.FC<CaHouseholdsWithUniversalCreditChartProps> = ({
	oaCode,
	radius,
}) => {
	const { aaUniversalCreditsByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<AAUniversalCreditsByCaQuery>(
		AAUniversalCreditsByCa,
		{ oaCode, radius },
	)

	const caDataset = datasets.find((d) => d.code === oaCode)

	if (!caDataset) {
		return null
	}

	const dataset = {
		id: 'Catchment Area',
		values: [...caDataset.values],
	}

	const model: Model = {
		labels: [...labels],
		datasets: [dataset],
	}

	return <HouseholdsWithUniversalCreditChart model={model} />
}

export { CaHouseholdsWithUniversalCreditChart }
