import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GrossDisposableHouseholdIncomesByLadCodeQuery } from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCode } from '../../graphql/queries/GrossDisposableHouseholdIncomesByLadCode'
import { formatCurrency } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface GDHIQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const GDHIByLad: React.FC<GDHIQuickFactProps> = ({ ladCode, compareRegion }) => {
	const data = useLazyLoadQuery<GrossDisposableHouseholdIncomesByLadCodeQuery>(
		GrossDisposableHouseholdIncomesByLadCode,
		{ ladCode },
	)
	const { labels, datasets } = data.grossDisposableHouseholdIncomesByLadCode

	const index = labels.length - 1
	const gdhiLad = datasets[0].values[index]
	const gdhiRegion = datasets[1] ? datasets[1].values[index] : 0
	const gdhiNational = datasets[2] ? datasets[2].values[index] : 0
	const comparison = compareRegion ? gdhiRegion : gdhiNational || 0

	return (
		<QuickFact
			heading="Gross Disposable Household Income"
			value={formatCurrency(gdhiLad, 0)}
			comparison={formatCurrency(comparison, 0)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<GDHIQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<GDHIByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as GDHIByLad }
