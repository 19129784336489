/**
 * @generated SignedSource<<14bd77117cfd7a2270d67426cdbe641f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RadiusValues = "FIVE" | "ONE" | "TEN" | "THREE" | "%future added value";
export type PopulationByCaQuery$variables = {
  futureOffset: number;
  includeIntermediates?: boolean | null | undefined;
  maxAge: number;
  minAge: number;
  oaCode: string;
  radius: RadiusValues;
  rangeSize: number;
  startYear: number;
};
export type PopulationByCaQuery$data = {
  readonly populationByCa: {
    readonly geographies: ReadonlyArray<{
      readonly ageRanges: ReadonlyArray<{
        readonly ageRange: string;
        readonly values: ReadonlyArray<number>;
      }>;
      readonly code: string;
    }>;
    readonly years: ReadonlyArray<number>;
  };
};
export type PopulationByCaQuery = {
  response: PopulationByCaQuery$data;
  variables: PopulationByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "futureOffset"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeIntermediates"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxAge"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minAge"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "oaCode"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "radius"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rangeSize"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "futureOffset",
        "variableName": "futureOffset"
      },
      {
        "kind": "Variable",
        "name": "includeIntermediates",
        "variableName": "includeIntermediates"
      },
      {
        "kind": "Variable",
        "name": "maxAge",
        "variableName": "maxAge"
      },
      {
        "kind": "Variable",
        "name": "minAge",
        "variableName": "minAge"
      },
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      },
      {
        "kind": "Variable",
        "name": "rangeSize",
        "variableName": "rangeSize"
      },
      {
        "kind": "Variable",
        "name": "startYear",
        "variableName": "startYear"
      }
    ],
    "concreteType": "PopulationProjections",
    "kind": "LinkedField",
    "name": "populationByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopulationGeography",
        "kind": "LinkedField",
        "name": "geographies",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PopulationAgeRange",
            "kind": "LinkedField",
            "name": "ageRanges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ageRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "values",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "years",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopulationByCaQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopulationByCaQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "626a7360a447a9253e3cdb0b576bba51",
    "id": null,
    "metadata": {},
    "name": "PopulationByCaQuery",
    "operationKind": "query",
    "text": "query PopulationByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n  $startYear: Int!\n  $rangeSize: Int!\n  $minAge: Int!\n  $maxAge: Int!\n  $futureOffset: Int!\n  $includeIntermediates: Boolean\n) {\n  populationByCa(oaCode: $oaCode, radius: $radius, startYear: $startYear, rangeSize: $rangeSize, minAge: $minAge, maxAge: $maxAge, futureOffset: $futureOffset, includeIntermediates: $includeIntermediates) {\n    geographies {\n      code\n      ageRanges {\n        ageRange\n        values\n      }\n    }\n    years\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb9d46bbd60990c378cc02ba65c8b9a6";

export default node;
