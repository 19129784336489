import { Model } from '../../shared/charts/types'
import { ElderlySitesChartSiteFragment$data } from './__generated__/ElderlySitesChartSiteFragment.graphql'

function getElderlySites(sites: ElderlySitesChartSiteFragment$data): Model {
	// Check if data is available
	if (!sites || !Array.isArray(sites)) {
		return { labels: [], datasets: [] }
	}

	// Define the years of interest
	const yearsOfInterest = [2014, 2024]

	// Initialize an object to store the results for each year
	const yearData: { [year: number]: { totalSites: number } } = {}

	// Initialize yearData for each year of interest
	yearsOfInterest.forEach((year) => {
		yearData[year] = {
			totalSites: 0,
		}
	})

	// Iterate through each site, filtering for those that specialize in care homes for the elderly
	sites.forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Process for each year of interest
		yearsOfInterest.forEach((year) => {
			if (registrationYear <= year) {
				// Increment total number of sites for that year
				yearData[year].totalSites += 1
			}
		})
	})

	// Prepare labels and datasets
	const labels: string[] = []
	const totalSitesValues: number[] = []

	yearsOfInterest.forEach((year) => {
		labels.push(year.toString())
		totalSitesValues.push(yearData[year].totalSites)
	})

	const datasets = [
		{
			id: 'Total Sites',
			values: totalSitesValues,
		},
	]

	return {
		labels: labels,
		datasets: datasets,
	}
}

export { getElderlySites }
