/**
 * @generated SignedSource<<2a537ae9af8da38a30b4a26682c58e90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CommunityCareServicesByLadCodeQuery$variables = {
  ladCode: string;
};
export type CommunityCareServicesByLadCodeQuery$data = {
  readonly communityCareServicesByLadCode: {
    readonly SelfFunded: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly StateFunded: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type CommunityCareServicesByLadCodeQuery = {
  response: CommunityCareServicesByLadCodeQuery$data;
  variables: CommunityCareServicesByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "values",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "CommunityCareServices",
    "kind": "LinkedField",
    "name": "communityCareServicesByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CommunityCareServiceData",
        "kind": "LinkedField",
        "name": "StateFunded",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CommunityCareServiceData",
        "kind": "LinkedField",
        "name": "SelfFunded",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunityCareServicesByLadCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunityCareServicesByLadCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3cf8c3dd65f83b1b9184922f2c5ad0ee",
    "id": null,
    "metadata": {},
    "name": "CommunityCareServicesByLadCodeQuery",
    "operationKind": "query",
    "text": "query CommunityCareServicesByLadCodeQuery(\n  $ladCode: String!\n) {\n  communityCareServicesByLadCode(ladCode: $ladCode) {\n    labels\n    StateFunded {\n      code\n      values\n    }\n    SelfFunded {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7289ff4d927d70cddcc3e72ac0349de7";

export default node;
