import React, { useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery } from '../../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { RadiusValues } from '../../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { LocalAuthorityByLadCode } from '../../../../graphql/queries/LocalAuthorityByLad'
import { CareFeeByLad } from '../../../../quickFacts/careFees/CareFeesByLad'
import { DementiaPrevalenceByCa } from '../../../../quickFacts/dementiaPrevalence/dementiaPrevalenceByCa'
import { ElderlyCareBedShortagesByCa } from '../../../../quickFacts/elderlyCareSupply/ElderlyCareBedShortagesByCa'
import { ElderlyCareSupplyRateByCa } from '../../../../quickFacts/elderlyCareSupply/ElderlyCareSupplyRateByCa'
import { ElderlyPopulationShareByCa } from '../../../../quickFacts/elderlyPopulationShare/ElderlyPopulationShareByCa'
import { GDHIByCa } from '../../../../quickFacts/gdhi/GDHIByCa'
import { HousePriceAverageByCa } from '../../../../quickFacts/housePriceAverage/HousePriceAverageByCa'
import { PopulationByCa } from '../../../../quickFacts/population/PopulationByCa'
import { StateFundedByCa } from '../../../../quickFacts/stateFunded/StateFundedByCa'
import { UnemploymentRatesByCa } from '../../../../quickFacts/unemploymentRates/UnemploymentRatesByCa'
import { YearsToBuyByCa } from '../../../../quickFacts/yearsToBuy/YearsToBuyByCa'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { ToggleButtonGroup } from '../../../../shared/ToggleButtonGroup'

interface CaQuickFactsProps {
	oaCode: string
	radius: RadiusValues
	ladCode: string
}

const CaQuickFacts: React.FC<CaQuickFactsProps> = ({ oaCode, radius, ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LocalAuthorityByLadCodeQuery>(LocalAuthorityByLadCode, {
		ladCode,
	})

	const regionName = la.regionName
	const countryName = la.countryName
	const [compareRegion, setCompareRegion] = useState(true)

	const toggleOptions = [
		{ label: regionName, value: 'region' },
		{ label: countryName, value: 'national' },
	]

	const handleToggleChange = (value: string) => {
		setCompareRegion(value === 'region')
	}
	return (
		<Container removePadding>
			<Row>
				<Col count={12}>
					<Panel padding={16}>
						<ToggleButtonGroup
							options={toggleOptions}
							activeValue={compareRegion ? 'region' : 'national'}
							onChange={handleToggleChange}
						/>
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<PopulationByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
				<Col count={6}>
					<StateFundedByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<ElderlyPopulationShareByCa age={65} oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
				<Col count={6}>
					<ElderlyPopulationShareByCa age={85} oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<ElderlyCareBedShortagesByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
				<Col count={6}>
					<ElderlyCareSupplyRateByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<CareFeeByLad ladCode={ladCode} />
				</Col>
				<Col count={6}>
					<DementiaPrevalenceByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<GDHIByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
				<Col count={6}>
					<UnemploymentRatesByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<HousePriceAverageByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
				<Col count={6}>
					<YearsToBuyByCa oaCode={oaCode} radius={radius} compareRegion={compareRegion} />
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<CaQuickFactsProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CaQuickFacts {...props} />
		</React.Suspense>
	)
}

export { Suspended as CaQuickFacts }
