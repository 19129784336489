import graphql from 'babel-plugin-relay/macro'

export const ClearCacheNode = graphql`
	mutation ClearCacheMutation($key: String!) {
		clearCache(key: $key) {
			success
			deleted
		}
	}
`
