import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { PopulationByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { PopulationByCa } from '../../graphql/queries/PopulationByCa'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface ElderlyPopulationShareQuickFactProps {
	age: number
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const ElderlyPopulationShareByCa: React.FC<ElderlyPopulationShareQuickFactProps> = ({
	age,
	oaCode,
	radius,
	compareRegion,
}) => {
	const { populationByCa: popTotal } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 0,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const { populationByCa: popElderly } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 0,
		minAge: age,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const popTotalCa = popTotal.geographies[0].ageRanges[0].values[0]
	const popTotalRegion = popTotal.geographies[popTotal.geographies.length - 2].ageRanges[0].values[0]
	const popTotalNational = popTotal.geographies[popTotal.geographies.length - 1].ageRanges[0].values[0]
	const popElderlyCa = popElderly.geographies[0].ageRanges[0].values[0]
	const popElderlyRegion = popElderly.geographies[popElderly.geographies.length - 2].ageRanges[0].values[0]
	const popElderlyNational = popElderly.geographies[popElderly.geographies.length - 1].ageRanges[0].values[0]

	const elderlyShareCa = (popElderlyCa / popTotalCa) * 100
	const elderlyShareRegion = (popElderlyRegion / popTotalRegion) * 100
	const elderlyShareNational = (popElderlyNational / popTotalNational) * 100
	const comparison = compareRegion ? elderlyShareRegion : elderlyShareNational

	return (
		<QuickFact
			heading={`Elderly Population Share (${age}+)`}
			value={formatPercentage(elderlyShareCa, 0)}
			comparison={formatPercentage(comparison, 0)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<ElderlyPopulationShareQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyPopulationShareByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyPopulationShareByCa }
