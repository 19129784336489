import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface ChartProps {
	model: Model
}

const ElderlyCareSupplyRateChart: React.FC<ChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Elderly Care Supply Rate</Subtitle>
			<Paragraph>
				Elderly bed supply rate for elderly with severe care needs | Based on Attendance Allowance Data from the
				Department for Work and Pensions, CQC care home data and demographic data from the Census 2021 |
				Regression-based forecast model from SH Proptech
			</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart
				model={model}
				aspectRatio={1}
				dataLabelDisplayOption="showForSelectedLabels"
				selectedLabels={['2020', '2024', 'E2028', 'E2032']}
			/>
		</>
	)
}
export { ElderlyCareSupplyRateChart }
