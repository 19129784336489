import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { AAAwardTypesByLadCodeQuery } from '../../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { AAAwardTypesByLadCode } from '../../graphql/queries/AAAwardTypesByLad'
import { SitesByLadCode } from '../../graphql/queries/SitesByLadCode'
import { getBedSupplyAndDemandHistoricalForecast } from '../../utils/bedForecastHelpers'
import { ElderlyBedShortagesForecastChartSiteFragment$key } from '../ElderlyBedShortagesForecastChart/__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'
import { ElderlyCareSupplyRateChart } from './ElderlyCareSupplyRateChart'
import { ElderlyCareSupplyRateChartSiteFragment } from './ElderlyCareSupplyRateChartSiteFragment'

const startYear = 2020
const endYear = 2032

interface LadChartProps {
	ladCode: string
}

const LadElderlyCareSupplyRateChart: React.FC<LadChartProps> = ({ ladCode }) => {
	// Fetch data
	const { aaAwardTypesByLadCode: aaData } = useLazyLoadQuery<AAAwardTypesByLadCodeQuery>(AAAwardTypesByLadCode, {
		ladCode,
	})

	const sitesData = useLazyLoadQuery<SitesByLadCodeQuery>(SitesByLadCode, {
		ladCode: ladCode,
	})

	const sites = useFragment<ElderlyBedShortagesForecastChartSiteFragment$key>(
		ElderlyCareSupplyRateChartSiteFragment,
		sitesData.sitesByLadCode.sites,
	)

	// Process data
	const { labels, datasets } = getBedSupplyAndDemandHistoricalForecast(aaData, sites, startYear, endYear)

	return (
		<>
			<ElderlyCareSupplyRateChart model={{ labels, datasets }} />
		</>
	)
}

export { LadElderlyCareSupplyRateChart }
