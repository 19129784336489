import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { RadiusValues, SitesByCaQuery } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCa } from '../../graphql/queries/SitesByCa'
import { SitesTableFragment$key } from './__generated__/SitesTableFragment.graphql'
import { SitesTable } from './SitesTable'
import { SitesTableFragment } from './SitesTableFragment'

interface SitesTableProps {
	oaCode: string
	radius: RadiusValues
}

const CaSitesTable: React.FC<SitesTableProps> = ({ oaCode, radius }) => {
	const data = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, { oaCode, radius })

	const sitesData = React.useMemo(() => {
		return data.sitesByCa || []
	}, [data])

	const sites = useFragment<SitesTableFragment$key>(SitesTableFragment, sitesData.sites)

	return <SitesTable sites={sites} />
}

export { CaSitesTable }
