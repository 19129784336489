import React, { useState } from 'react'

export interface SegmentButtonOptions {
	children: React.ReactNode
	value: string
	disabled: boolean
}

interface SegmentedButtonProps {
	options: SegmentButtonOptions[]
	onClick: (value: string) => void // Callback for when a button is selected
}

const SegmentedButton: React.FC<SegmentedButtonProps> = ({ options, onClick }) => {
	const [, setSelectedValue] = useState<string | null>(null)

	const handleButtonClick = (value: string) => {
		setSelectedValue(value)
		onClick(value)
	}

	const style = {
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '0.15rem',
		paddingBottom: '0.15rem',
		fontSize: '.25rem',
		height: 30,
	}

	return (
		<div className="btn-group">
			{options.map((option) => (
				<button
					type="button"
					className="btn btn-outline-secondary"
					style={style}
					key={option.value}
					onClick={() => handleButtonClick(option.value)}
					disabled={option.disabled} // Set this to true if you need to disable
				>
					{option.children}
				</button>
			))}
		</div>
	)
}

export { SegmentedButton }
