import React, { useState } from 'react'
import * as i from 'react-feather'

import { LadHeader } from '../../../../shared/LadHeader'
import { LaTable } from '../../../../shared/LaTable/LaTable'
import { SpaceBetween } from '../../../../shared/layout/Alignment'
import { Col, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Panel } from '../../../../shared/Panel'
import { SegmentedButton } from '../../../../shared/SegmentedButton'
import { Filter } from './Filter'
import { LadQuickFacts } from './LadQuickFacts'
import { Map as MyMap } from './Map'

interface MapProps {
	ladCode: string
}

const Explorer: React.FC<MapProps> = ({ ladCode }) => {
	const [view, setView] = useState('map')
	const [showMarkers, setShowMarkers] = useState(false)
	const [activeFilter, setFilter] = useState('')

	const buttonOpts = [
		{
			children: <i.Map size={14} color="#666" />,
			value: 'map',
			disabled: view === 'map',
		},
		{
			children: <i.List size={14} color="#666" />,
			value: 'list',
			disabled: view === 'list',
		},
	]

	const handleButtonClick = (value: string) => {
		setView(value)
	}

	const handleSwitchChange = (value: boolean) => {
		setShowMarkers(value)
	}

	const handleFilterChange = (value: string) => {
		setFilter(value)
	}

	return (
		<>
			<Row>
				<Col count={3}>
					<Row>
						<Col count={12}>
							<Panel padding={32}>
								<LadHeader ladCode={ladCode} />
							</Panel>
						</Col>
					</Row>
					<VerticalSpace size="sm" />
					<LadQuickFacts ladCode={ladCode} />
				</Col>
				<Col count={9}>
					<Panel padding={8} height="auto">
						<SpaceBetween>
							<SegmentedButton options={buttonOpts} onClick={handleButtonClick} />
							{view === 'map' && (
								<Filter
									activeFilter={activeFilter}
									showValues={showMarkers}
									onShowValuesChange={handleSwitchChange}
									onFilterChange={handleFilterChange}
								/>
							)}
						</SpaceBetween>
						<VerticalSpace size="xs" />
						{view === 'map' ? (
							<MyMap ladCode={ladCode} showMarkers={showMarkers} activeFilter={activeFilter} height="80vh" />
						) : (
							<LaTable />
						)}
					</Panel>
				</Col>
			</Row>
		</>
	)
}

export { Explorer }
