import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { HousePricesByLadCodeQuery } from '../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { HousePricesByLadCode } from '../../graphql/queries/HousePricesByLad'
import { Model } from '../../shared/charts/types'
import { HousePricesChart } from './HousePricesChart'

interface LadHousePricesChartProps {
	ladCode: string
}

const LadHousePricesChart: React.FC<LadHousePricesChartProps> = ({ ladCode }) => {
	const { housePricesByLadCode: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<HousePricesByLadCodeQuery>(
		HousePricesByLadCode,
		{ ladCode },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <HousePricesChart model={model} />
}

export { LadHousePricesChart }
