import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { AAAwardTypesByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByCaQuery as PopResponse } from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { AAAwardTypesByCa } from '../../graphql/queries/AAAwardTypesByCa'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { PopulationByCa } from '../../graphql/queries/PopulationByCa'
import { calculateElderlyWithCareNeedShare } from '../../utils/calculateElderlyWithCareNeedShare'
import { ElderlyCareNeedPrevalenceRateChart } from './ElderlyCareNeedPrevalenceRateChart'

interface CaElderlyCareNeedPrevalenceRateChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyCareNeedPrevalenceRateChart: React.FC<CaElderlyCareNeedPrevalenceRateChartProps> = ({
	oaCode,
	radius,
}) => {
	const { populationByCa: p } = useLazyLoadQuery<PopResponse>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear - 5,
		futureOffset: 5,
		minAge: 65,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: true,
	})

	const { aaAwardTypesByCa: aa } = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, {
		oaCode,
		radius,
	})

	const codes = p.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodes, {
		codes,
	})

	const model = calculateElderlyWithCareNeedShare(p, [...aa.higherRate], geos)

	return <ElderlyCareNeedPrevalenceRateChart model={model} />
}

export { CaElderlyCareNeedPrevalenceRateChart }
