import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCode } from '../../graphql/queries/SitesByLadCode'
import { SitesTableFragment$key } from './__generated__/SitesTableFragment.graphql'
import { SitesTable } from './SitesTable'
import { SitesTableFragment } from './SitesTableFragment'

interface SitesTableProps {
	ladCode: string
}

const LaSitesTable: React.FC<SitesTableProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<SitesByLadCodeQuery>(SitesByLadCode, { ladCode })

	const sitesData = React.useMemo(() => {
		return data.sitesByLadCode || []
	}, [data])

	// Use useFragment on each item in the array
	const sites = useFragment<SitesTableFragment$key>(SitesTableFragment, sitesData.sites)

	return <SitesTable sites={sites} />
}

export { LaSitesTable }
