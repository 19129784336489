import React from 'react'
import * as i from 'react-feather'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { AddOperatorToGroupButton } from '../../actions/AddOperatorToGroup'
import { RemoveOperatorFromGroupButton } from '../../actions/RemoveOperatorFromGroup'
import { OperatorByIdQuery } from '../../graphql/queries/__generated__/OperatorByIdQuery.graphql'
import { OperatorById } from '../../graphql/queries/OperatorById'
import { Page } from '../../layout/page/Page'
import { LeftAligned, SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle } from '../../shared/Text'
import { OperatorFragment$key } from './__generated__/OperatorFragment.graphql'
import { OperatorFragment } from './OperatorFragment'

type RouteParams = { id: string }

const Operator: React.FC = () => {
	const { id = '' } = useParams<RouteParams>() // Get the id from the URL

	const { operatorById: operatorData } = useLazyLoadQuery<OperatorByIdQuery>(OperatorById, { id })

	const op = useFragment<OperatorFragment$key>(OperatorFragment, operatorData)

	const hasGroup = !!(op.operatorGroupId && op.operatorGroupId !== '')

	return (
		<Page title={`Operator - ${op.name}`}>
			<Container>
				<Row>
					<Col count={3}></Col>
					<Col count={6}>
						<SpaceBetween>
							<PageTitle>{op.name}</PageTitle>
							{hasGroup && <RemoveOperatorFromGroupButton operatorId={op.id} groupId={op.operatorGroupId} />}
							{!hasGroup && <AddOperatorToGroupButton operatorId={op.id} />}
						</SpaceBetween>
					</Col>
				</Row>
				<Row>
					<Col count={3}></Col>
					<Col count={6}>
						<Panel padding={16}>
							<div className="list-group">
								<OperatorRow label="Name" icon={<i.User />}>
									{op.name}
								</OperatorRow>
								<OperatorRow label="Operator Group" icon={<i.Users />}>
									<a href={`/groups/${op.operatorGroupId}`}>{op.operatorGroupName}</a>
								</OperatorRow>
								<OperatorRow label="Companies House Number" icon={<i.Home />}>
									{op.companiesHouseNumber}
								</OperatorRow>
								<OperatorRow label="Constituency" icon={<i.MapPin />}>
									{op.constituency}
								</OperatorRow>
								<OperatorRow label="CQC Provider ID" icon={<i.CreditCard />}>
									{op.cqcProviderId}
								</OperatorRow>
								<OperatorRow label="Created At" icon={<i.Calendar />}>
									{new Date(op.createdAt).toLocaleString()}
								</OperatorRow>
								<OperatorRow label="Inspection Directorate" icon={<i.Search />}>
									{op.inspectionDirectorate}
								</OperatorRow>
								<OperatorRow label="Local Authority" icon={<i.Map />}>
									{op.localAuthority}
								</OperatorRow>
								<OperatorRow label="Main Phone Number" icon={<i.Phone />}>
									{op.mainPhoneNumber}
								</OperatorRow>
								<OperatorRow label="ODS Code" icon={<i.Code />}>
									{op.odsCode}
								</OperatorRow>
								<OperatorRow label="ONSPD ICB Code" icon={<i.BarChart />}>
									{op.onspdIcbCode}
								</OperatorRow>
								<OperatorRow label="ONSPD ICB Name" icon={<i.Tag />}>
									{op.onspdIcbName}
								</OperatorRow>
								<OperatorRow label="Lat \ Long" icon={<i.MapPin />}>
									{op.onspdLatitude},{op.onspdLongitude}
								</OperatorRow>
								<OperatorRow label="Organisation Type" icon={<i.Type />}>
									{op.organisationType}
								</OperatorRow>
								<OperatorRow label="Ownership Type" icon={<i.Droplet />}>
									{op.ownershipType}
								</OperatorRow>
								<OperatorRow label="Postal Address" icon={<i.Mail />}>
									{`${op.postalAddressLine1} ${op.postalAddressLine2} ${op.postalAddressTownCity} ${op.postalCode}`}
								</OperatorRow>
								<OperatorRow label="Region" icon={<i.Globe />}>
									{op.region}
								</OperatorRow>
								<OperatorRow label="Registration Date" icon={<i.Calendar />}>
									{new Date(op.registrationDate).toLocaleDateString()}
								</OperatorRow>
								<OperatorRow label="Registration Status" icon={<i.Info />}>
									{op.registrationStatus}
								</OperatorRow>
								<OperatorRow label="Source" icon={<i.Database />}>
									{op.source}
								</OperatorRow>
								<OperatorRow label="Type" icon={<i.List />}>
									{op.type}
								</OperatorRow>
								<OperatorRow label="Updated At" icon={<i.Calendar />}>
									{new Date(op.updatedAt).toLocaleString()}
								</OperatorRow>
								<OperatorRow label="Website" icon={<i.Globe />}>
									<a href={`http://${op.website}`} target="_blank" rel="noopener noreferrer">
										{op.website}
									</a>
								</OperatorRow>
							</div>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

const OperatorRow = ({
	label,
	children,
	icon,
}: {
	label: string
	children: React.ReactNode
	icon: React.ReactNode
}) => (
	<div className="list-group-item">
		<LeftAligned>
			<div>{icon}</div>
			<HorizontalSpace size="xs" />
			<div>
				<strong>{label}:</strong> {children}
			</div>
		</LeftAligned>
	</div>
)

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Operator />
		</React.Suspense>
	)
}

export { Suspended as Operator }
