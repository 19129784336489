/**
 * @generated SignedSource<<b244e7cc4a5ebeb2e5d81bf028a594f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoveOperatorsFromGroupMutation$variables = {
  id: string;
  operatorIds: ReadonlyArray<string>;
};
export type RemoveOperatorsFromGroupMutation$data = {
  readonly removeOperatorsFromOperatorGroup: {
    readonly id: string;
    readonly name: string;
    readonly operators: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type RemoveOperatorsFromGroupMutation = {
  response: RemoveOperatorsFromGroupMutation$data;
  variables: RemoveOperatorsFromGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "operatorIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "operatorIds",
        "variableName": "operatorIds"
      }
    ],
    "concreteType": "OperatorGroup",
    "kind": "LinkedField",
    "name": "removeOperatorsFromOperatorGroup",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "OperatorGroupListItem",
        "kind": "LinkedField",
        "name": "operators",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveOperatorsFromGroupMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveOperatorsFromGroupMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "97c94a4e2b7f4387a84e1eaa533e3ba7",
    "id": null,
    "metadata": {},
    "name": "RemoveOperatorsFromGroupMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveOperatorsFromGroupMutation(\n  $id: ID!\n  $operatorIds: [ID!]!\n) {\n  removeOperatorsFromOperatorGroup(id: $id, operatorIds: $operatorIds) {\n    id\n    name\n    operators {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "478e36253d0800ccb6902eed587074b0";

export default node;
