import React from 'react'

import { CaElderlyBedShortagesForecastChart } from '../../../../charts/ElderlyBedShortagesForecastChart/CaElderlyBedShortagesForecastChart'
import { RadiusValues } from '../../../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { PageTitle, ParagraphBold, SubtitleBox } from '../../../../shared/Text'
import { CaElderlyCareQuickFacts } from './CaElderlyCareQuickFacts'

interface ElderlyCareMarketProps {
	oaCode: string
	radius: RadiusValues
	ladCode: string
}

const ElderlyCareMarket: React.FC<ElderlyCareMarketProps> = ({ oaCode, radius, ladCode }) => {
	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox>Elderly Care Market</SubtitleBox>
						<PageTitle>Catchment Area </PageTitle>
						<ParagraphBold>
							Comprehensive data and insights about the elderly care market within the specified catcment area. It
							includes key statistics, forecasts, and comparisons to help understand the current and future state of
							elderly care services in the area.
						</ParagraphBold>
					</Panel>
					<VerticalSpace size="sm" />
					<CaElderlyCareQuickFacts oaCode={oaCode} radius={radius} ladCode={ladCode} />
				</Col>
				<Col count={8}>
					<Panel height={'auto'}>
						<VerticalSpace size="sm" />
						<CaElderlyBedShortagesForecastChart oaCode={oaCode} radius={radius} />
						<VerticalSpace size="sm" />
					</Panel>
					<VerticalSpace size="md" />
					<Panel height={'auto'}>Elderly Bed Supply Rate Comparison:Coming Soon</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
		</Container>
	)
}

const Suspended: React.FC<ElderlyCareMarketProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<ElderlyCareMarket {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareMarket }
