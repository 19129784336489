import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../../../Constants'
import { GeographiesByCodesQuery } from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	PopulationByCaQuery,
	RadiusValues,
} from '../../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { SocialGradesByCaQuery } from '../../../../graphql/queries/__generated__/SocialGradesByCaQuery.graphql'
import { GeographiesByCodes } from '../../../../graphql/queries/GeographiesByCodes'
import { PopulationByCa } from '../../../../graphql/queries/PopulationByCa'
import { SocialGradesByCa } from '../../../../graphql/queries/SocialGradesByCa'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { TextPlaceholder } from '../../../../shared/placeholder/Placeholder'

interface AIPopulationSummaryProps {
	oaCode: string
	radius: RadiusValues
}

const AIPopulationSummary: React.FC<AIPopulationSummaryProps> = ({ oaCode, radius }) => {
	const { populationByCa: populationAgeGroups } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 20,
		minAge: 0,
		maxAge: 100,
		futureOffset: 5,
		includeIntermediates: false,
	})

	const socialGradeData = useLazyLoadQuery<SocialGradesByCaQuery>(SocialGradesByCa, {
		oaCode,
		radius,
	})

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes: socialGradeData.socialGradesByCa.datasets.map((d) => d.code),
	})

	const dataToAnalyse = {
		Population: populationAgeGroups,
		SocialGradeDistribution: socialGradeData,
		geos,
	}

	const prompt = `
    Analyse the population structure of the Local Authority ${JSON.stringify(dataToAnalyse.geos[0].name)}, comparing to its Region, and England based on the following datasets: 
	Population Growth by Age groups Data: ${JSON.stringify(dataToAnalyse.Population)}
	Social Grade Distribution Data: ${JSON.stringify(dataToAnalyse.SocialGradeDistribution)}
	and structure your answer starting with a headline like an action title of maximum 30 characters wrapping up the main finding followed by a descriptive paragraph between 90-120 characters.
  `
	const iteration = 4
	const key = `population-summary-${oaCode}-${radius}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIPopulationSummary: React.FC<AIPopulationSummaryProps> = (props) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<AIPopulationSummary {...props} />
		</React.Suspense>
	)
}

export { SuspendedAIPopulationSummary as AIPopulationSummary }
