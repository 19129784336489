/**
 * @generated SignedSource<<b14895f9b93017b38befe34b3f047449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OperatorGroupsQuery$variables = {
  search?: string | null | undefined;
};
export type OperatorGroupsQuery$data = {
  readonly operatorGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly operators: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};
export type OperatorGroupsQuery = {
  response: OperatorGroupsQuery$data;
  variables: OperatorGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "OperatorGroup",
    "kind": "LinkedField",
    "name": "operatorGroups",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "OperatorGroupListItem",
        "kind": "LinkedField",
        "name": "operators",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorGroupsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OperatorGroupsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "18871bc68c51ae318ccfa74121b441df",
    "id": null,
    "metadata": {},
    "name": "OperatorGroupsQuery",
    "operationKind": "query",
    "text": "query OperatorGroupsQuery(\n  $search: String\n) {\n  operatorGroups(search: $search) {\n    id\n    name\n    operators {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ec2f54f1429daf8ca7d61ed46c634c4";

export default node;
