import { Model } from '../../shared/charts/types'
import { filterEldery } from '../../utils/sites'
import { ElderlyBedsTimeSeriesChartSiteFragment$data } from './__generated__/ElderlyBedsTimeSeriesChartSiteFragment.graphql'

function getElderlyBeds(sites: ElderlyBedsTimeSeriesChartSiteFragment$data): Model {
	// Check if data is available
	if (!sites || !Array.isArray(sites)) {
		return { labels: [], datasets: [] }
	}

	// Define the range of years
	const startYear = 2010
	const endYear = 2024

	// Initialize an object to store the results for each year
	const yearData: { [year: string]: { totalBeds: number } } = {}

	// Initialize yearData for each year
	for (let year = startYear; year <= endYear; year++) {
		yearData[year] = {
			totalBeds: 0,
		}
	}

	// Iterate through each site, filtering for those that specialize in care homes for the elderly
	filterEldery(sites).forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Only process if registrationYear is valid and within the range
		if (registrationYear && registrationYear <= endYear) {
			for (let year = registrationYear; year <= endYear; year++) {
				// Increment total number of beds for that year
				yearData[year].totalBeds += site.numberOfBeds || 0
			}
		}
	})

	// Prepare labels and datasets
	const labels: string[] = []
	const totalBedsValues: number[] = []

	for (let year = startYear; year <= endYear; year++) {
		labels.push(year.toString())
		totalBedsValues.push(yearData[year].totalBeds)
	}

	const datasets = [
		{
			id: 'Total Beds',
			values: totalBedsValues,
		},
	]

	return {
		labels: labels,
		datasets: datasets,
	}
}
export { getElderlyBeds }
