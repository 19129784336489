import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../../../Constants'
import {
	AAAwardTypesByCaQuery,
	RadiusValues,
} from '../../../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { GeographiesByCodesQuery } from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByCaQuery } from '../../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { AAAwardTypesByCa } from '../../../../graphql/queries/AAAwardTypesByCa'
import { GeographiesByCodes } from '../../../../graphql/queries/GeographiesByCodes'
import { PopulationByCa } from '../../../../graphql/queries/PopulationByCa'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { Dataset } from '../../../../shared/charts/types'
import { TextPlaceholder } from '../../../../shared/placeholder/Placeholder'
import { calculateElderlyWithCareNeedShare } from '../../../../utils/calculateElderlyWithCareNeedShare'
import { transformToMarkdownTable } from '../../../../utils/transformToMarkdownTable'

interface AIElderlySummaryProps {
	oaCode: string
	radius: RadiusValues
}

const AIElderlySummary: React.FC<AIElderlySummaryProps> = ({ oaCode, radius }) => {
	const { populationByCa: elderly } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 10,
		minAge: 65,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	// Fetch total population data
	const { populationByCa: total } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 10,
		minAge: 0,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const codes = total.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear - 5,
		futureOffset: 5,
		minAge: 65,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: true,
	})

	const { aaAwardTypesByCa: aa } = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, {
		oaCode,
		radius,
	})

	const elderlyWithCareNeedData = calculateElderlyWithCareNeedShare(pop, [...aa.higherRate], geos)
	const elderlyWithCareNeedFormatted = transformToMarkdownTable(elderlyWithCareNeedData)

	const labels = total.years.map((year) => year.toString())
	const datasets: Dataset[] = total.geographies.map((geography, i) => ({
		id: geos.find((g) => g.code === geography.code)?.name || geography.code,
		values: geography.ageRanges[0].values.map((value, index) => {
			return (elderly.geographies[i].ageRanges[0].values[index] / value) * 100
		}),
	}))

	const elderlyPopulationShareFormatted = transformToMarkdownTable({ labels, datasets })

	// Construct the prompt
	const prompt = `
You are a data analyst specializing in demographics and elderly care home in the year 2024. Analyze the elderly population trends of the catchment area, comparing it to its Region and England based solely on the data provided below.

Data:
1. Catchment area: ${JSON.stringify(geos)}

2. Elderly Population Share (% of total population) in 2018, today (2024) and forecasted data for 2034:
  like this:

| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|

${JSON.stringify(elderlyPopulationShareFormatted)}

3. Elderly Care Need Prevalence Rates (% of elderly needing care) from 2021, 2022, 2023 and the newest and current rate of year 2024:
   like this:

| Year | Name of the Catchment Area | Name of the Region | England |
|------|----------------|---------------|---------|
${JSON.stringify(elderlyWithCareNeedFormatted)}

**Instructions:**

Structure Your Response Without Labels and make sure you state the correct comparison for prevalence rate for the current year 2024:
- Start directly with a concise headline (maximum 30 characters) summarizing the main finding. Do not include any labels or introductory words or quotes.
- Follow with a paragraph of 90-200 characters that accurately reflects your analysis. Do not include any labels or introductory words.

Focus on Accurate Data Analysis:
- Base all interpretations and conclusions directly on the data provided.
- **When comparing values, restate the specific numbers for each area to ensure accuracy.**
- Perform precise, step-by-step comparisons for each data point, explicitly stating which values are higher or lower, especially focusing on the current year.
- Always double-check the numerical values before making any claims.
- Present comparisons clearly to avoid misinterpretations.
- Ensure the correct logical flow: compare values directly and discuss which is higher or lower without mixing them up.
- State the correct comparison for prevalence rate for the current year 2024.
- Do not make assumptions beyond the given data.
`

	const iteration = 43
	const key = `elderly-summary-${oaCode}-${radius}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIElderlySummary: React.FC<AIElderlySummaryProps> = (props) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<AIElderlySummary {...props} />
		</React.Suspense>
	)
}

export { SuspendedAIElderlySummary as AIElderlySummary }
