import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { PopulationByLadCodeQuery } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { PopulationByLadCode as Request } from '../../graphql/queries/PopulationByLad'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface PopulationQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const PopulationByLad: React.FC<PopulationQuickFactProps> = ({ ladCode, compareRegion }) => {
	const { populationByLadCode: pop } = useLazyLoadQuery<PopulationByLadCodeQuery>(Request, {
		ladCode,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 0,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const lad = pop.geographies[0].ageRanges[0].values[0]
	const region = pop.geographies[1].ageRanges[0].values[0]
	const national = pop.geographies[2].ageRanges[0].values[0]
	const comparison = compareRegion ? region : national

	return (
		<QuickFact
			heading="Population"
			value={formatNumber(lad)}
			comparison={formatNumber(comparison)}
			isComparable={false}
		/>
	)
}

const Suspended: React.FC<PopulationQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<PopulationByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as PopulationByLad }
