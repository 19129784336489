import graphql from 'babel-plugin-relay/macro'

export const CareHomesSitesByCaFragment = graphql`
	fragment CareHomesSitesByCaFragment on Site @relay(plural: true) {
		id
		name
		location
		specialisms
	}
`
