import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { CaElderlyBedsTimeSeriesChart } from '../../../../charts/ElderlyBedsTimeSeriesChart/CaElderlyBedsTimeSeriesChart'
import { CaElderlySitesChart } from '../../../../charts/ElderlySitesChart/CaElderlySitesChart'
import { OutputAreasByMileRadiusQuery } from '../../../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { RadiusValues } from '../../../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCaQuery } from '../../../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { OutputAreasByMileRadius } from '../../../../graphql/queries/OutputAreasByMileRadius'
import { SitesByCa } from '../../../../graphql/queries/SitesByCa'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { MapFeatures, Marker } from '../../../../shared/map/MapFeatures'
import { CaOperatorsTable } from '../../../../shared/operatorsTable/CaOperatorsTable'
import { Panel } from '../../../../shared/Panel'
import { CaSitesTable } from '../../../../shared/sitesTable/CaSitesTable'
import { Heading, Paragraph, SubtitleBox } from '../../../../shared/Text'
import { ToggleButtonGroup } from '../../../../shared/ToggleButtonGroup'
import { theme } from '../../../../Theme'
import { combineCollection } from '../../../../utils/featureCollections'
import { filterEldery } from '../../../../utils/sites'
import { CareHomesCatchmentAreaFragment$key } from './__generated__/CareHomesCatchmentAreaFragment.graphql'
import { CareHomesSitesByCaFragment$key } from './__generated__/CareHomesSitesByCaFragment.graphql'
import { CareHomesCatchmentAreaFragment } from './CareHomesCatchmentAreaFragment'
import { CareHomesSitesByCaFragment } from './CareHomesSitesByCaFragment'

interface CareHomesProps {
	oaCode: string
	radius: RadiusValues
	lat: number
	long: number
}

const CareHomes: React.FC<CareHomesProps> = ({ oaCode, radius, lat, long }) => {
	const [view, setView] = useState('careHomes')

	const options = [
		{ label: 'Care Homes', value: 'careHomes' },
		{ label: 'Operators', value: 'operators' },
	]

	const handleViewChange = (value: string) => {
		setView(value)
	}

	const { outputAreasByMileRadius } = useLazyLoadQuery<OutputAreasByMileRadiusQuery>(OutputAreasByMileRadius, {
		lat: `${lat}`,
		long: `${long}`,
		radius,
	})
	const ca = useFragment<CareHomesCatchmentAreaFragment$key>(CareHomesCatchmentAreaFragment, outputAreasByMileRadius)

	const features: Feature<Polygon | MultiPolygon>[] = ca.outputAreas.map((o) => {
		const geometry = JSON.parse(o.geometry!)
		return {
			type: 'Feature',
			geometry,
			properties: {
				color: theme.colors.primary,
				alpha: 0,
			},
		}
	})

	const featureCollection = turf.featureCollection(features)
	const featureCollections = combineCollection(featureCollection)

	const { sitesByCa: si } = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, { oaCode, radius })

	const allSites = useFragment<CareHomesSitesByCaFragment$key>(CareHomesSitesByCaFragment, si.sites)
	const sites = filterEldery(allSites)

	const locations: Marker[] = sites.map((siteData) => {
		return {
			location: JSON.parse(siteData.location!),
			name: siteData.name,
			id: siteData.id,
		}
	})

	const title = `${sites.length} Care Homes in catchment area`

	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox text-transform={'none'}>Care Providers</SubtitleBox>
						<Heading level={2}>{title}</Heading>
						<VerticalSpace size="xs" />
						<Paragraph>
							Comprehensive data on care homes, including detailed information on care providers, the number of sites,
							and bed availability over time. Users can view and analyze the geographical distribution of care homes and
							operators within a specified radius, utilizing interactive charts and tables to gain insights into the
							care home landscape.
						</Paragraph>
					</Panel>
					<VerticalSpace size="sm" />
					<Panel padding={8} height={'auto'}>
						<MapFeatures features={featureCollections} locations={locations} />
					</Panel>
				</Col>
				<Col count={8}>
					<Panel padding={16} height={'auto'}>
						<ToggleButtonGroup options={options} activeValue={view} onChange={handleViewChange} />
					</Panel>
					<VerticalSpace size="sm" />
					{view === 'careHomes' ? (
						<>
							<Row>
								<Col count={6}>
									<Panel>
										<CaElderlySitesChart oaCode={oaCode} radius={radius} />
									</Panel>
								</Col>
								<Col count={6}>
									<Panel height={'auto'}>
										<CaElderlyBedsTimeSeriesChart oaCode={oaCode} radius={radius} />
									</Panel>
								</Col>
							</Row>
							<VerticalSpace size="sm" />
							<Row>
								<Col count={12}>
									<Panel>
										<CaSitesTable oaCode={oaCode} radius={radius} />
									</Panel>
								</Col>
							</Row>
						</>
					) : (
						<Panel>
							<CaOperatorsTable oaCode={oaCode} radius={radius} />
						</Panel>
					)}
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<CareHomesProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareHomes {...props} />
		</React.Suspense>
	)
}

export { Suspended as CareHomes }
