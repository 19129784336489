import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { PopulationByLadCodeQuery } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { PopulationByLadCode } from '../../graphql/queries/PopulationByLad'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface ElderlyPopulationShareQuickFactProps {
	age: number
	ladCode: string
	compareRegion?: boolean
}

const ElderlyPopulationShareByLad: React.FC<ElderlyPopulationShareQuickFactProps> = ({
	age,
	ladCode,
	compareRegion,
}) => {
	const { populationByLadCode: popTotal } = useLazyLoadQuery<PopulationByLadCodeQuery>(PopulationByLadCode, {
		ladCode,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 0,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const { populationByLadCode: popElderly } = useLazyLoadQuery<PopulationByLadCodeQuery>(PopulationByLadCode, {
		ladCode,
		startYear: currentYear,
		futureOffset: 0,
		minAge: age,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const popTotalLad = popTotal.geographies[0].ageRanges[0].values[0]
	const popTotalRegion = popTotal.geographies[1].ageRanges[0].values[0]
	const popTotalNational = popTotal.geographies[2].ageRanges[0].values[0]
	const popElderlyLad = popElderly.geographies[0].ageRanges[0].values[0]
	const popElderlyRegion = popElderly.geographies[1].ageRanges[0].values[0]
	const popElderlyNational = popElderly.geographies[2].ageRanges[0].values[0]

	const elderlyShareLad = (popElderlyLad / popTotalLad) * 100
	const elderlyShareRegion = (popElderlyRegion / popTotalRegion) * 100
	const elderlyShareNational = (popElderlyNational / popTotalNational) * 100
	const comparison = compareRegion ? elderlyShareRegion : elderlyShareNational

	return (
		<QuickFact
			heading={`Elderly Population Share (${age}+)`}
			value={formatPercentage(elderlyShareLad, 0)}
			comparison={formatPercentage(comparison, 0)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<ElderlyPopulationShareQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyPopulationShareByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyPopulationShareByLad }
