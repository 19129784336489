import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareStatsByTypes = graphql`
	query ElderlyCareStatsByTypesQuery($types: [GeographyTypes!]!) {
		elderlyCareStatsByTypes(types: $types) {
			...ElderlyCareStatsFragment
			...LaTableElderlyCareStatsFragment
			...ElderlyCareStatsCaFragment
		}
	}
`
