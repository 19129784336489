import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { AAUniversalCreditsByLadCodeQuery } from '../../graphql/queries/__generated__/AAUniversalCreditsByLadCodeQuery.graphql'
import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { AAUniversalCreditsByLadCode } from '../../graphql/queries/AAUniversalCreditsByLad'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { Model } from '../../shared/charts/types'
import { HouseholdsWithUniversalCreditChart } from './HouseholdsWithUniversalCreditChart'

interface LadHouseholdsWithUniversalCreditChartProps {
	ladCode: string
}

const LadHouseholdsWithUniversalCreditChart: React.FC<LadHouseholdsWithUniversalCreditChartProps> = ({ ladCode }) => {
	const { aaUniversalCreditsByLadCode: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<AAUniversalCreditsByLadCodeQuery>(AAUniversalCreditsByLadCode, { ladCode })

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const ladDataset = datasets.find((d) => d.code === ladCode)

	if (!ladDataset) {
		return null
	}

	const dataset = {
		id: geos.find((g) => g.code === ladDataset.code)?.name || ladCode,
		values: [...ladDataset.values],
	}

	const model: Model = {
		labels: [...labels],
		datasets: [dataset],
	}

	return <HouseholdsWithUniversalCreditChart model={model} />
}

export { LadHouseholdsWithUniversalCreditChart }
