/**
 * @generated SignedSource<<ac310a7f197d0483a836506eefee569a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesTableFragment$data = ReadonlyArray<{
  readonly currentRatings: any | null | undefined;
  readonly id: any;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly operatorName: string | null | undefined;
  readonly postalAddressTownCity: string;
  readonly registrationDate: any | null | undefined;
  readonly serviceTypes: string | null | undefined;
  readonly specialisms: any | null | undefined;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "SitesTableFragment";
}>;
export type SitesTableFragment$key = ReadonlyArray<{
  readonly " $data"?: SitesTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SitesTableFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SitesTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalAddressTownCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentRatings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "c34c68b1a64ee59490dab2336016967d";

export default node;
