import graphql from 'babel-plugin-relay/macro'

export const TenureTypesByLadCode = graphql`
	query TenureTypesByLadCodeQuery($ladCode: String!) {
		tenureTypesByLadCode(ladCode: $ladCode) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
