import { CellContext, flexRender } from '@tanstack/react-table'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { OperatorGroupsQuery } from '../../graphql/queries/__generated__/OperatorGroupsQuery.graphql'
import { OperatorGroups as Request } from '../../graphql/queries/OperatorGroups'
import { Page } from '../../layout/page/Page'
import { DataTable, RowData } from '../../shared/DataTable'
import { Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle, Paragraph } from '../../shared/Text'

const OperatorGroups: React.FC = () => {
	const navigate = useNavigate()
	const data = useLazyLoadQuery<OperatorGroupsQuery>(Request, {})

	const tableData = React.useMemo(() => {
		return data.operatorGroups || []
	}, [data])

	const columns = React.useMemo(
		() => [
			{ Header: 'Name', accessorKey: 'name' },
			{ Header: 'Operators', accessorKey: 'operators' },
		],
		[],
	)

	const onCell = (cellContext: CellContext<RowData, unknown>) => {
		const cellValue = cellContext.getValue()

		if (cellContext.column.id === 'operators') {
			const names = (cellValue as { name: string }[]).map((op) => op.name)
			names.sort()
			return (
				<>
					{names.map((name, index) => (
						<Paragraph key={index}>{name}</Paragraph>
					))}
				</>
			)
		}

		return <Paragraph>{cellValue == null ? '-' : flexRender(cellContext.column.columnDef.cell, cellContext)}</Paragraph>
	}

	const onRowClick = (id: string) => {
		navigate(`/groups/${id}`)
	}

	return (
		<Page title="Operator Groups">
			<Container>
				<Row>
					<div className="col-12">
						<PageTitle>Operator Groups</PageTitle>
					</div>
				</Row>
				<VerticalSpace size="lg" />
				<Row>
					<div className="col-12">
						<Panel>
							{tableData.length > 0 ? (
								<>
									<DataTable columns={columns} data={tableData} onCell={onCell} onRowClick={onRowClick} />
								</>
							) : (
								<p>No OperatorGroups found.</p>
							)}
						</Panel>
					</div>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<OperatorGroups />
		</React.Suspense>
	)
}

export { Suspended as OperatorGroups }
