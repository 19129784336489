import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { PopulationByLadCodeQuery } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { PopulationByLadCode } from '../../graphql/queries/PopulationByLad'
import { Model } from '../../shared/charts/types'
import { ElderlyPopulationIn10YearsChart } from './ElderlyPopulationIn10YearsChart'

interface LadElderlyPopulationIn10YearsChartProps {
	ladCode: string
}

const LadElderlyPopulationIn10YearsChart: React.FC<LadElderlyPopulationIn10YearsChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<PopulationByLadCodeQuery>(PopulationByLadCode, {
		ladCode: ladCode,
		startYear: currentYear,
		futureOffset: 10,
		minAge: 65,
		maxAge: 100,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const model: Model = {
		labels: data.populationByLadCode?.years.map((year) => year.toString()),
		datasets: [
			{
				id: 'sometihn',
				values: data.populationByLadCode?.geographies[0].ageRanges[0].values.map((value) => value),
			},
		],
	}

	return <ElderlyPopulationIn10YearsChart model={model} />
}

export { LadElderlyPopulationIn10YearsChart }
