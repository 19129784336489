/**
 * @generated SignedSource<<d7dec0a9781ce55323b124d79c2c0170>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorFragment$data = {
  readonly alias: string | null | undefined;
  readonly charityNumber: string | null | undefined;
  readonly companiesHouseNumber: string | null | undefined;
  readonly constituency: string;
  readonly contacts: string | null | undefined;
  readonly cqcProviderId: string;
  readonly createdAt: any;
  readonly deregistrationDate: any | null | undefined;
  readonly emails: string | null | undefined;
  readonly employees: string | null | undefined;
  readonly id: any;
  readonly inspectionAreas: string | null | undefined;
  readonly inspectionCategories: string | null | undefined;
  readonly inspectionDirectorate: string | null | undefined;
  readonly linkedinId: string | null | undefined;
  readonly localAuthority: string | null | undefined;
  readonly location: string | null | undefined;
  readonly mainPhoneNumber: string | null | undefined;
  readonly name: string;
  readonly odsCode: string | null | undefined;
  readonly onspdIcbCode: string | null | undefined;
  readonly onspdIcbName: string | null | undefined;
  readonly onspdLatitude: number | null | undefined;
  readonly onspdLongitude: number | null | undefined;
  readonly operatorGroupId: string | null | undefined;
  readonly operatorGroupName: string | null | undefined;
  readonly organisationType: string;
  readonly ownershipType: string;
  readonly phoneNumber: string | null | undefined;
  readonly postalAddressCounty: string | null | undefined;
  readonly postalAddressLine1: string | null | undefined;
  readonly postalAddressLine2: string | null | undefined;
  readonly postalAddressTownCity: string;
  readonly postalCode: string;
  readonly region: string | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly registrationStatus: string;
  readonly relationships: string | null | undefined;
  readonly sector: string | null | undefined;
  readonly serviceTypes: string | null | undefined;
  readonly servicesProviders: string | null | undefined;
  readonly sites: ReadonlyArray<{
    readonly constituency: string | null | undefined;
    readonly cqcProviderId: string;
    readonly createdAt: any;
    readonly emails: string | null | undefined;
    readonly employees: string | null | undefined;
    readonly id: any;
    readonly inspectionDirectorate: string | null | undefined;
    readonly ladCode: string | null | undefined;
    readonly linkedinId: string | null | undefined;
    readonly location: string | null | undefined;
    readonly mainPhoneNumber: string | null | undefined;
    readonly name: string;
    readonly odsCode: string | null | undefined;
    readonly onspdIcbCode: string | null | undefined;
    readonly onspdIcbName: string | null | undefined;
    readonly onspdLatitude: number | null | undefined;
    readonly onspdLongitude: number | null | undefined;
    readonly operatorId: string | null | undefined;
    readonly operatorName: string | null | undefined;
    readonly organisationType: string | null | undefined;
    readonly phoneNumber: string | null | undefined;
    readonly postalAddressCounty: string | null | undefined;
    readonly postalAddressLine1: string | null | undefined;
    readonly postalAddressLine2: string | null | undefined;
    readonly postalAddressTownCity: string;
    readonly postalCode: string;
    readonly regionCode: string | null | undefined;
    readonly registrationDate: any | null | undefined;
    readonly registrationStatus: string | null | undefined;
    readonly relationships: string | null | undefined;
    readonly sector: string | null | undefined;
    readonly serviceTypes: string | null | undefined;
    readonly servicesProviders: string | null | undefined;
    readonly source: string;
    readonly type: string;
    readonly updatedAt: any;
    readonly uprn: string | null | undefined;
    readonly website: string | null | undefined;
  }>;
  readonly source: string;
  readonly type: string;
  readonly updatedAt: any;
  readonly uprn: string | null | undefined;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "OperatorFragment";
};
export type OperatorFragment$key = {
  readonly " $data"?: OperatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "constituency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cqcProviderId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employees",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inspectionDirectorate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainPhoneNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "odsCode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onspdIcbCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onspdIcbName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onspdLatitude",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onspdLongitude",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkedinId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organisationType",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressCounty",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressLine1",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressLine2",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressTownCity",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationStatus",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relationships",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "servicesProviders",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sector",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceTypes",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uprn",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OperatorFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "charityNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companiesHouseNumber",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contacts",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deregistrationDate",
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inspectionAreas",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inspectionCategories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localAuthority",
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorGroupName",
      "storageKey": null
    },
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownershipType",
      "storageKey": null
    },
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    (v20/*: any*/),
    (v21/*: any*/),
    (v22/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "region",
      "storageKey": null
    },
    (v23/*: any*/),
    (v24/*: any*/),
    (v25/*: any*/),
    (v26/*: any*/),
    (v27/*: any*/),
    (v28/*: any*/),
    (v29/*: any*/),
    (v30/*: any*/),
    (v31/*: any*/),
    (v32/*: any*/),
    (v33/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "sites",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ladCode",
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "operatorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "operatorName",
          "storageKey": null
        },
        (v17/*: any*/),
        (v18/*: any*/),
        (v19/*: any*/),
        (v20/*: any*/),
        (v21/*: any*/),
        (v22/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "regionCode",
          "storageKey": null
        },
        (v23/*: any*/),
        (v24/*: any*/),
        (v25/*: any*/),
        (v26/*: any*/),
        (v27/*: any*/),
        (v28/*: any*/),
        (v29/*: any*/),
        (v30/*: any*/),
        (v31/*: any*/),
        (v32/*: any*/),
        (v33/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Operator",
  "abstractKey": null
};
})();

(node as any).hash = "c4e37ff99c83ea7ca9bf023e5da3faa3";

export default node;
