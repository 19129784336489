import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { AAAwardTypesByCaQuery } from '../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { RadiusValues, SitesByCaQuery } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { AAAwardTypesByCa } from '../../graphql/queries/AAAwardTypesByCa'
import { SitesByCa } from '../../graphql/queries/SitesByCa'
import { Model } from '../../shared/charts/types'
import { getBedSupplyAndDemandHistoricalForecast } from '../../utils/bedForecastHelpers'
import { ElderlyBedShortagesForecastChartSiteFragment$key } from './__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'
import { ElderlyBedShortagesForecastChart } from './ElderlyBedShortagesForecastChart'
import { ElderlyBedShortagesForecastChartSiteFragment } from './ElderlyBedShortagesForecastChartSiteFragment'

const startYear = 2020
const endYear = 2032

interface CaChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyBedShortagesForecastChart: React.FC<CaChartProps> = ({ oaCode, radius }) => {
	// TODO: Fetch CA data
	const { aaAwardTypesByCa: aaData } = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, {
		oaCode,
		radius,
	})

	const sitesData = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, {
		oaCode,
		radius,
	})

	// sites by region - times series of bed counts

	// sites by country - times series of bed counts

	const sites = useFragment<ElderlyBedShortagesForecastChartSiteFragment$key>(
		ElderlyBedShortagesForecastChartSiteFragment,
		sitesData.sitesByCa.sites,
	)

	// Process data
	const model: Model = getBedSupplyAndDemandHistoricalForecast(aaData, sites, startYear, endYear)

	return <ElderlyBedShortagesForecastChart model={model} />
}

export { CaElderlyBedShortagesForecastChart }
