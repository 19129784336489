import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LocalAuthoritiesQuery } from '../../graphql/queries/__generated__/LocalAuthoritiesQuery.graphql'
import { LocalAuthorities } from '../../graphql/queries/LocalAuthorities'
import { Select } from '../Select'
import { LaSelectFragment$key } from './__generated__/LaSelectFragment.graphql'
import { LaSelectFragment } from './LaSelectFragment'

interface LaSelectProps {
	onChange: (value: string) => void
	value?: string
}

const LaSelect: React.FC<LaSelectProps> = ({ onChange, value }) => {
	const data = useLazyLoadQuery<LocalAuthoritiesQuery>(LocalAuthorities, {})

	const laData = React.useMemo(() => {
		return data.localAuthorities || []
	}, [data])

	// Use useFragment on each item in the array
	const options = laData.map((la: LaSelectFragment$key) => {
		const laFragment = useFragment(LaSelectFragment, la)

		return {
			value: laFragment.ladCode,
			label: laFragment.name,
		}
	})

	options.unshift({ value: '', label: 'Select Local Authority' })

	return <Select options={options} value={value} onChange={onChange} />
}

export { LaSelect }
