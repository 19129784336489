import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareSupplyRateQuickFactFragment = graphql`
	fragment ElderlyCareSupplyRateQuickFactFragment on ElderlyCareStats {
		stats {
			code
			type
			careSupplyRate
			numberOfBeds
			higherRateAllowance
		}
	}
`
