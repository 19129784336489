/**
 * @generated SignedSource<<846aae64c98f77359edcd43e5409163b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyBedsTimeSeriesChartSiteFragment$data = ReadonlyArray<{
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly specialisms: any | null | undefined;
  readonly " $fragmentType": "ElderlyBedsTimeSeriesChartSiteFragment";
}>;
export type ElderlyBedsTimeSeriesChartSiteFragment$key = ReadonlyArray<{
  readonly " $data"?: ElderlyBedsTimeSeriesChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedsTimeSeriesChartSiteFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ElderlyBedsTimeSeriesChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "24771fb206f16b5fdaa9e8890011ecea";

export default node;
