import graphql from 'babel-plugin-relay/macro'

export const SitesTableFragment = graphql`
	fragment SitesTableFragment on Site @relay(plural: true) {
		id
		name
		postalAddressTownCity
		operatorName
		numberOfBeds
		registrationDate
		currentRatings
		specialisms
		serviceTypes
		website
	}
`
