import graphql from 'babel-plugin-relay/macro'

export const OperatorFragment = graphql`
	fragment OperatorFragment on Operator {
		id
		alias
		charityNumber
		companiesHouseNumber
		constituency
		contacts
		cqcProviderId
		createdAt
		deregistrationDate
		emails
		employees
		inspectionDirectorate
		inspectionAreas
		inspectionCategories
		localAuthority
		mainPhoneNumber
		name
		odsCode
		onspdIcbCode
		onspdIcbName
		onspdLatitude
		onspdLongitude
		operatorGroupId
		operatorGroupName
		linkedinId
		location
		organisationType
		ownershipType
		phoneNumber
		postalAddressCounty
		postalAddressLine1
		postalAddressLine2
		postalAddressTownCity
		postalCode
		region
		registrationDate
		registrationStatus
		relationships
		servicesProviders
		sector
		serviceTypes
		source
		type
		updatedAt
		uprn
		website

		sites {
			id
			constituency
			cqcProviderId
			createdAt
			emails
			employees
			inspectionDirectorate
			ladCode
			mainPhoneNumber
			name
			odsCode
			onspdIcbCode
			onspdIcbName
			onspdLatitude
			onspdLongitude
			linkedinId
			location
			organisationType
			operatorId
			operatorName
			phoneNumber
			postalAddressCounty
			postalAddressLine1
			postalAddressLine2
			postalAddressTownCity
			postalCode
			regionCode
			registrationDate
			registrationStatus
			relationships
			servicesProviders
			sector
			serviceTypes
			source
			type
			updatedAt
			uprn
			website
		}
	}
`
