import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GrossDisposableHouseholdIncomesByCaQuery } from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { HousePricesByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { GrossDisposableHouseholdIncomesByCa } from '../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { HousePricesByCa } from '../../graphql/queries/HousePricesByCa'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface YearsToBuyQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const YearsToBuyByCa: React.FC<YearsToBuyQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const housePriceData = useLazyLoadQuery<HousePricesByCaQuery>(HousePricesByCa, { oaCode, radius })
	const { labels: hpLabels, datasets: hpDatasets } = housePriceData.housePricesByCa

	const hpIndex = hpLabels.length - 1
	const housePriceCa = hpDatasets[0].values[hpIndex]
	const housePriceRegion = hpDatasets[hpDatasets.length - 2].values[hpIndex]
	const housePriceNational = hpDatasets[hpDatasets.length - 1].values[hpIndex]

	const gdhiData = useLazyLoadQuery<GrossDisposableHouseholdIncomesByCaQuery>(GrossDisposableHouseholdIncomesByCa, {
		oaCode,
		radius,
	})
	const { labels: gdhiLabels, datasets: gdhiDatasets } = gdhiData.grossDisposableHouseholdIncomesByCa

	const gdhiIndex = gdhiLabels.length - 1
	const gdhiCa = gdhiDatasets[0].values[gdhiIndex]
	const gdhiRegion = gdhiDatasets[gdhiDatasets.length - 2].values[gdhiIndex]
	const gdhiNational = gdhiDatasets[gdhiDatasets.length - 1].values[gdhiIndex]

	const yearsToBuyCa = housePriceCa / gdhiCa
	const yearsToBuyRegion = housePriceRegion / gdhiRegion
	const yearsToBuyNational = housePriceNational / gdhiNational

	const comparison = compareRegion ? yearsToBuyRegion : yearsToBuyNational

	return (
		<QuickFact
			heading="Years-to-Buy"
			value={formatNumber(yearsToBuyCa, 0, 1)}
			comparison={formatNumber(comparison, 0, 1)}
			isComparable={true}
			reverseComparison={true}
		/>
	)
}

const Suspended: React.FC<YearsToBuyQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<YearsToBuyByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as YearsToBuyByCa }
