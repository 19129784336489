import graphql from 'babel-plugin-relay/macro'

export const AddOperatorsToGroupMutation = graphql`
	mutation AddOperatorsToGroupMutation($id: ID!, $operatorIds: [ID!]!) {
		addOperatorsToOperatorGroup(id: $id, operatorIds: $operatorIds) {
			id
			name
			operators {
				id
				name
			}
		}
	}
`
